/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useContext, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { CardContent, Grid, Toolbar } from '@mui/material';

const KatangaDialogContext = createContext();

export function useKatangaDialog() {
  return useContext(KatangaDialogContext);
}

// eslint-disable-next-line react/prop-types
export function KatangaDialogProvider({ children }) {
  const [dialogOpen, setDialogOpen] = useState();
  const [submitDisabled, setSubmitDisabled] = useState(true);

  return (
    <KatangaDialogContext.Provider
      value={{
        dialogOpen,
        submitDisabled,
        setSubmitDisabled,
        setDialogOpen,
      }}
    >
      {children}
    </KatangaDialogContext.Provider>
  );
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.primary.light,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6" color="secondary">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(7),
  },
}));

export default function KatangaDialog({
  // eslint-disable-next-line react/prop-types
  children, submitAction, onClose, modalTitle,
}) {
  const classes = useStyles();
  const {
    dialogOpen,
    submitDisabled,
  } = useKatangaDialog();

  if (!dialogOpen) return <></>;
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={dialogOpen}
      maxWidth="md"
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        {modalTitle}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <CardContent>
          <Toolbar />
          {children}
        </CardContent>
      </DialogContent>
      {submitAction && (
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Button disabled={submitDisabled} autoFocus onClick={submitAction} color="primary" size="large">
              Submit
            </Button>
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
}
