import React from 'react';
import { StaffDetails } from '@think-zambia-foundation/core-lib/components';

export default function KatangaStaffDetails() {
  return (
    <StaffDetails claims={[
      'katangaAdmin',
      'katangaEnrollmentReport',
      'katangaReportAdmin',
      'katangaScoreAdmin',
      'katangaStudent',
      'katangaPaymentAdmin',
      'katangaStudentsAdmin',
      'katangaStudentUser',
      'staffAdmin',
      'katangaSchoolAdmin',
    ]}
    />
  );
}
