import React from 'react';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import { SmartTypography, CollapsibleCard } from '@think-zambia-foundation/core-lib/components';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';

function StudentContactInfo() {
  const { student } = useKatanga();

  const [guardian, setGuardian] = React.useState({});

  React.useEffect(() => {
    if (student && student.guardian) {
      setGuardian(student.guardian);
    }
  }, [student]);

  return (
    <CollapsibleCard title="Contact Information">
      <CardContent>
        <SmartTypography caption="Guardian Name" text={`${guardian.firstName} ${guardian.lastName}`} />
        <SmartTypography
          caption="Address"
          text={guardian.address
            ? `${guardian.address.addressLine1} ${guardian.address.addressLine2 ?? ''}`
            : ''}
        />
        <Grid container spacing={3}>
          <Grid item xs={6} sm={4}>
            <SmartTypography
              caption="Phone number"
              variant="phone"
              text={guardian.phone1}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <SmartTypography
              caption="Secondary number"
              variant="phone"
              text={guardian.phone2}
            />
          </Grid>
        </Grid>
        <SmartTypography caption="Email" text={guardian.email} />
      </CardContent>
    </CollapsibleCard>
  );
}

export default StudentContactInfo;
