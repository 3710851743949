import {
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { Loading, SmartTypography, CollapsibleCard } from '@think-zambia-foundation/core-lib/components';
import { useHistory, useLocation, useParams } from 'react-router';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import SchoolFeesCard from './SchoolFeesCard';
import SubjectsCard from './SubjectsCard';

function useQueryParam() {
  return new URLSearchParams(useLocation().search);
}

export default function GradeInformationCard() {
  const { schoolId } = useParams();
  const query = useQueryParam();
  const selectedGradeId = query.get('selectedGradeId');
  const selectedClassId = query.get('selectedClassId');

  const {
    addingEnrollmentClass,
    school,
    setAddEnrollmentClassDialogOpen,
    // setAddSubjectDialogOpen,
    setChangeTuitionDialogOpen,
    // setReferenceId,
  } = useKatanga();

  const handleClickAddEnrollmentClass = () => {
    setAddEnrollmentClassDialogOpen(true);
  };

  const handleClickChangeTuition = () => {
    setChangeTuitionDialogOpen(true);
  };

  const history = useHistory();
  function handleSelectEnrollmentClass(e, _class) {
    if (!_class.enrollmentClassId) return;
    e.preventDefault();
    history.push(`/school/${schoolId}/?selectedGradeId=${selectedGradeId}&selectedClassId=${_class.enrollmentClassId}`);
  }

  const [_classes, setClasses] = useState();
  const [_school, setSchool] = useState();
  const [_grade, setGrade] = useState();
  useEffect(() => {
    if (school) {
      setSchool(school);
      const selectedGrade = school.enrollmentGrades
        .find((grade) => grade.enrollmentGradeId === selectedGradeId);
      setGrade(selectedGrade);
      if (selectedGrade) {
        setClasses(selectedGrade.enrollmentClasses);
      } else {
        setClasses();
      }
    }
  }, [school, selectedGradeId]);

  if (!_school || !_grade) return <></>;
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12}>
          <CollapsibleCard
            title="Grade Information"
            cardActionCallback={handleClickChangeTuition}
            icon={<EditTwoToneIcon />}
          >
            <List dense>
              <ListItem>
                <ListItemText>
                  <SmartTypography
                    caption="Grade"
                    text={_grade.enrollmentGradeType}
                    variant="name"
                  />
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <SmartTypography
                    caption="Tuition"
                    text={`ZMW ${_grade.tuition}`}
                    variant="name"
                  />
                </ListItemText>
              </ListItem>
            </List>
          </CollapsibleCard>
        </Grid>
        <Grid item xs={12}>
          <SchoolFeesCard title="Grade Fees" fees={_grade.fees} referenceId={_grade.enrollmentGradeId} />
        </Grid>
        <Grid item xs={12}>
          <SubjectsCard subjects={_grade.subjects} referenceId={_grade.enrollmentGradeId} title="Grade Subjects" />
        </Grid>
        <Grid item xs={12}>
          <CollapsibleCard
            title="Classes"
            cardActionCallback={handleClickAddEnrollmentClass}
            icon={<AddTwoToneIcon />}
          >
            {_classes && (
            <List dense>
              {' '}
              {_classes.map((_class) => (
                <ListItem
                  button
                  value={_class.enrollmentClassName}
                  key={_class.enrollmentClassId}
                  onClick={(e) => {
                    handleSelectEnrollmentClass(e, _class);
                  }}
                  selected={selectedClassId === _class.enrollmentClassId}
                >
                  <ListItemText>
                    <SmartTypography
                      text={_class.enrollmentClassName}
                      caption={`Teacher: ${_class.staff.firstName} ${_class.staff.lastName}`}
                      variant="name"
                    />
                  </ListItemText>
                </ListItem>
              ))}
              {addingEnrollmentClass && (
                <ListItem key="loading">
                  <Loading />
                </ListItem>
              )}
            </List>
            )}
          </CollapsibleCard>
        </Grid>
      </Grid>
    </>
  );
}
