import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Grid } from '@mui/material';
import { useAuth } from '@think-zambia-foundation/core-lib/context';
import ChangeTuitionDialog from '../../components/ChangeTuitionDialog';
import AddEnrollmentClassDialog from '../../components/AddEnrollmentClassDialog';
import CreateSchoolDialog from '../../components/CreateSchoolDialog/CreateSchoolDialog';
import AddFeesDialog from '../../components/AddFeesDialog/AddFeesDialog';
import AddSubjectDialog from '../../components/AddSubjectDialog';
import SchoolInformationCard from './SchoolInformationCard';
import GradeInformationCard from './GradeInformationCard';
import ClassInformationCard from './ClassInformationCard';

export default function SchoolDetails() {
  const { schoolId } = useParams();
  const {
    addFeesDialogOpen,
    fetchSchool,
    fetchSchoolData,
    school,
    setSchool,
    successAddingEnrollmentClass,
    successAddingSubject,
  } = useKatanga();

  // refetch
  useEffect(() => {
    if (schoolId) {
      fetchSchool(schoolId);
    }
  }, [schoolId]);

  useEffect(() => {
    if (!addFeesDialogOpen && schoolId) {
      fetchSchool(schoolId);
    }
  }, [addFeesDialogOpen]);

  useEffect(() => {
    fetchSchool(schoolId);
  }, [successAddingEnrollmentClass]);

  useEffect(() => {
    fetchSchool(schoolId);
  }, [successAddingSubject]);

  useEffect(() => {
    if (fetchSchoolData && fetchSchoolData.school) {
      setSchool(fetchSchoolData.school);
    }
    // eslint-disable-next-line
  }, [fetchSchoolData]);

  const { hasPermissionToClaim } = useAuth();
  if (!hasPermissionToClaim('katangaSchoolAdmin')) return <>You do not have permissions to this page. contact the system admin</>;
  if (!school) return <></>;
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item sm={4} xs={12}>
          <SchoolInformationCard />
        </Grid>
        <Grid item sm={4} xs={12}>
          <GradeInformationCard />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ClassInformationCard />
        </Grid>
      </Grid>
      <AddEnrollmentClassDialog />
      <AddFeesDialog />
      <AddSubjectDialog />
      <ChangeTuitionDialog />
      <CreateSchoolDialog />
    </>
  );
}
