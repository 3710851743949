import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

function GuardianInformationRegistrationForm({
  guardian,
  setGuardianId,
  setGuardianFirstName,
  setGuardianLastName,
}) {
  const classes = useStyles();

  return (
    <div>
      <Card className={classes.root} variant="outlined" square>
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={12}>
              <Typography color="textSecondary" gutterBottom>
                Guardian Information
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                fullWidth
                id="guardian-id"
                label="Guardian NRC"
                value={guardian.id}
                onChange={setGuardianId}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                fullWidth
                id="guardian-first-name"
                label="Guardian First Name"
                value={guardian.firstName}
                onChange={setGuardianFirstName}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                fullWidth
                id="guardian-last-name"
                label="Guardian Last Name"
                value={guardian.lastName}
                onChange={setGuardianLastName}
              />
            </Grid>
            <Grid item sm={3} />
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

GuardianInformationRegistrationForm.propTypes = {
  guardian: PropTypes.func.isRequired,
  setGuardianId: PropTypes.func.isRequired,
  setGuardianFirstName: PropTypes.func.isRequired,
  setGuardianLastName: PropTypes.func.isRequired,
};

export default GuardianInformationRegistrationForm;
