import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import { CardMedia } from '@mui/material';
import { SmartTypography, CollapsibleCard } from '@think-zambia-foundation/core-lib/components';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import { useAuth } from '@think-zambia-foundation/core-lib/context';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import GetInitials from '../Common';
import CreateEnrollmentDialog from '../CreateEnrollmentDialog';

function ApplicationInfo() {
  const [loading, setLoading] = useState(false);
  const {
    application,
    createEnrollmentDialogOpen,
    setCreateEnrollmentDialogOpen,
    registerStudent,
    setRegister,
    archiveApplication,
    creatingEnrollment,
    rejectingApplication,
    rejectApplication,
  } = useKatanga();
  const { hasPermissionToClaim } = useAuth();

  useEffect(() => {
    if (createEnrollmentDialogOpen
      || creatingEnrollment
      || rejectingApplication) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    createEnrollmentDialogOpen,
    creatingEnrollment,
    rejectingApplication,
  ]);

  if (!application) return <></>;
  return (
    <CollapsibleCard
      title="Application Information"
      cardActionCallback={() => archiveApplication({ applicationId: application.applicationId })}
      icon={<ArchiveOutlinedIcon />}
      disableCard={!(hasPermissionToClaim('katangaAdmin'))}
    >
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={3} sm={3}>
            <CardMedia>
              <Avatar>
                {GetInitials(application.student.firstName, application.student.lastName)}
              </Avatar>
            </CardMedia>
          </Grid>
          <Grid item xs={9} sm={9}>
            <SmartTypography
              caption="Name"
              text={`${application.student.firstName} ${application.student.lastName}`}
              variant="name"
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <SmartTypography
          caption="Applying To"
          text={`${application.school.name}`}
        />
        <SmartTypography
          caption="Grade"
          text={`${application.grade.enrollmentGradeType}`}
        />
        <SmartTypography
          caption="Previous School"
          text={application.previousSchool || 'N/A'}
        />
        <SmartTypography
          variant="name"
          caption="Application Status"
          text={`${application.applicationStatus.toLowerCase()}`}
        />
        <br />
        {hasPermissionToClaim('katangaStudentsAdmin') && application.student.studentStatus === 'PRE_REGISTERED' && (
          <>
            <Grid container spacing={2}>
              <Grid item>
                <LoadingButton
                  onClick={() => {
                    setCreateEnrollmentDialogOpen(true);
                    registerStudent(application.applicationId, 'ACTIVE');
                    setRegister(true);
                  }}
                  endIcon={<CheckIcon />}
                  loading={loading}
                  loadingPosition="end"
                  variant="outlined"
                  color="secondary"
                  style={{ borderRadius: 0 }}
                >
                  Register
                </LoadingButton>
              </Grid>
              <Grid item>
                <LoadingButton
                  onClick={() => {
                    rejectApplication({ applicationId: application.applicationId });
                  }}
                  endIcon={<CloseIcon />}
                  loading={loading}
                  loadingPosition="end"
                  variant="outlined"
                  color="secondary"
                  style={{ borderRadius: 0 }}
                >
                  Reject
                </LoadingButton>
              </Grid>
            </Grid>
          </>
        )}
      </CardContent>
      <CreateEnrollmentDialog />
    </CollapsibleCard>
  );
}

export default ApplicationInfo;
