/* eslint-disable react/jsx-props-no-spreading */
import {
  Autocomplete,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import CalendarTodayTwoToneIcon from '@mui/icons-material/CalendarTodayTwoTone';
import { SmartTypography } from '@think-zambia-foundation/core-lib/components';
import KatangaDialog, { KatangaDialogProvider, useKatangaDialog } from '../KatangaDialog/KatangaDialog';

const terms = ['ONE', 'TWO', 'THREE'];
const currentYear = (new Date()).getFullYear();
const years = Array.from(new Array(5), (val, index) => `${(currentYear - index)}`);

function Wrapped() {
  const [errorMessage] = useState();
  const {
    selectedTerm,
    selectedYear,
    selectTermAndYearDialogOpen,
    setCookie,
    setSelectedTerm,
    setSelectedYear,
    setSelectTermAndYearDialogOpen,
  } = useKatanga();

  const [term, setTerm] = useState(selectedTerm);
  const [year, setYear] = useState(selectedYear);

  const {
    setDialogOpen,
    setSubmitDisabled,
  } = useKatangaDialog();

  useEffect(() => {
    setDialogOpen(selectTermAndYearDialogOpen);
    // eslint-disable-next-line
  }, [selectTermAndYearDialogOpen]);

  useEffect(() => {
    setSubmitDisabled(false);
  }, []);

  return (
    <>
      <KatangaDialog
        modalTitle="Select Term & Year"
        submitAction={() => {
          setSelectTermAndYearDialogOpen(false);
          setSelectedTerm(term);
          setSelectedYear(year);
          setCookie('katangaSelectedTerm', term, { path: '/' });
          setCookie('katangaSelectedYear', year, { path: '/' });
        }}
        onClose={() => setSelectTermAndYearDialogOpen(false)}
      >
        <Container maxWidth="xs">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant="body2" color="error" align="center">{errorMessage}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                onChange={(e, value) => {
                  setTerm(value);
                }}
                id="select-term-autocomplete"
                options={terms ?? []}
                value={selectedTerm}
                disableClearable
                noOptionsText="No term options: contact admin"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Term"
                    variant="outlined"
                    margin="dense"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                onChange={(e, value) => {
                  setYear(value);
                }}
                id="select-year-autocomplete"
                options={years ?? []}
                value={selectedYear}
                disableClearable
                noOptionsText="No year options: contact admin"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Year"
                    variant="outlined"
                    margin="dense"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Container>
      </KatangaDialog>
    </>
  );
}

export function SelectTermAndYearDialog() {
  return (
    <KatangaDialogProvider>
      <Wrapped />
    </KatangaDialogProvider>
  );
}

function SelectTermAndYear() {
  const {
    selectedTerm,
    selectedYear,
    setSelectTermAndYearDialogOpen,
  } = useKatanga();

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        spacing={3}
      >
        <Grid item>
          <SmartTypography
            caption="Term"
            text={selectedTerm}
            variant="name"
          />
        </Grid>
        <Grid item>
          <SmartTypography
            caption="Year"
            text={selectedYear}
            variant="name"
          />
        </Grid>
        <Grid item>
          <IconButton
            edge="end"
            onClick={() => setSelectTermAndYearDialogOpen(true)}
            size="large"
          >
            <CalendarTodayTwoToneIcon color="secondary" />
          </IconButton>
        </Grid>
      </Grid>
      <SelectTermAndYearDialog />
    </>
  );
}

export default SelectTermAndYear;
