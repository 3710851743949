import React from 'react';
import PeopleOutlineTwoToneIcon from '@mui/icons-material/PeopleOutlineTwoTone';
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded';
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';
import SchoolTwoToneIcon from '@mui/icons-material/SchoolTwoTone';
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import AttachMoneySharpIcon from '@mui/icons-material/AttachMoneySharp';

const menu = [
  {
    title: 'Students',
    items: [
      {
        id: '1',
        claim: 'katangaStudentUser',
        text: 'Students',
        secondaryText: 'active students',
        link: '/',
        icon: <PeopleOutlineTwoToneIcon style={{ fontSize: '1.5em' }} color="secondary" />,
      },
      {
        id: '2',
        claim: 'katangaStudentsAdmin',
        text: 'Applications',
        secondaryText: 'pending applications',
        link: '/application',
        icon: <AssignmentTwoToneIcon style={{ fontSize: '1.5em' }} color="secondary" />,
      },
      {
        id: '3',
        claim: 'katangaStudent',
        text: 'My Students',
        secondaryText: 'my active students',
        link: '/mystudents',
        icon: <PeopleOutlineTwoToneIcon style={{ fontSize: '1.5em' }} color="secondary" />,
      },
    ],
  },
  {
    title: 'Reports',
    items: [
      {
        id: '4',
        claim: 'katangaEnrollmentReport',
        text: 'Enrollment Report',
        secondaryText: 'enrolled students by term',
        link: '/report/enrollment',
        icon: <PlaylistAddCheckRoundedIcon style={{ fontSize: '1.5em' }} color="secondary" />,
      },
      {
        id: '5',
        text: 'Payment Report',
        claim: 'katangaReportAdmin',
        secondaryText: 'payment history by term',
        link: '/report/payment',
        icon: <PaymentRoundedIcon style={{ fontSize: '1.5em' }} color="secondary" />,
      },
      {
        id: '6',
        text: 'Capacity Report',
        claim: 'katangaReportAdmin',
        secondaryText: 'capacity snapshots per term',
        link: '/report/capacity',
        icon: <AttachMoneySharpIcon style={{ fontSize: '1.5em' }} color="secondary" />,
      },
    ],
  },
  {
    title: 'School Administration',
    items: [
      {
        id: '7',
        claim: 'staffAdmin',
        text: 'Staff',
        secondaryText: 'manage staff',
        link: '/staff',
        icon: <SchoolTwoToneIcon style={{ fontSize: '1.5em' }} color="secondary" />,
      },
      {
        id: '8',
        claim: 'katangaSchoolAdmin',
        text: 'Schools',
        secondaryText: 'manage school',
        link: '/school',
        icon: <AccountBalanceTwoToneIcon style={{ fontSize: '1.5em' }} color="secondary" />,
      },
    ],
  },
];

export default menu;
