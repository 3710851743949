import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField, Typography,
} from '@mui/material';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import { useLocation } from 'react-router';
import Loading from '../Loading';

function useQueryParam() {
  return new URLSearchParams(useLocation().search);
}

export default function ChangeTuitionDialog() {
  const query = useQueryParam();
  const selectedGradeId = query.get('selectedGradeId');
  const [newTuitionAmount, setNewTuitionAmount] = useState();
  const [confirmNewTuitionAmount, setConfirmNewTuitionAmount] = useState();
  const {
    changeTuitionDialogOpen,
    setChangeTuitionDialogOpen,
    successUpdatingEnrollmentGrade,
    updateEnrollmentGrade,
    updatingEnrollmentGrade,
  } = useKatanga();

  const handleSubmitChangeTuition = () => {
    updateEnrollmentGrade(selectedGradeId, { tuition: newTuitionAmount });
  };

  useEffect(() => {
    if (successUpdatingEnrollmentGrade) {
      setChangeTuitionDialogOpen(false);
    }
    // eslint-disable-next-line
  }, [successUpdatingEnrollmentGrade]);

  if (updatingEnrollmentGrade) return <Loading />;
  return (
    <Dialog
      maxWidth="md"
      open={changeTuitionDialogOpen}
      onClose={() => setChangeTuitionDialogOpen(false)}
      aria-labelledby="change-tuition-dialog-title"
    >
      <DialogTitle id="change-tuition-dialog-title">
        <Typography align="center">
          Change Tuition
        </Typography>
      </DialogTitle>
      <DialogContent>
        <FormControl>
          <Container component="main" maxWidth="xs">
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="new-tuition"
              label="New Tuition Amount"
              name="New Tuition Amount"
              type="number"
              inputProps={{ min: 0, style: { textAlign: 'right' } }}
              onChange={(e) => setNewTuitionAmount(e.target.value)}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="confirm-new-tuition"
              type="number"
              inputProps={{ min: 0, style: { textAlign: 'right' } }}
              label="Confirm New Tuition Amount"
              name="Confirm New Tuition Amount"
              style={{ marginBottom: '2em' }}
              onChange={(e) => setConfirmNewTuitionAmount(e.target.value)}
            />
          </Container>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            onClick={() => setChangeTuitionDialogOpen(false)}
            color="primary"
            size="large"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmitChangeTuition}
            color="primary"
            size="large"
            disabled={confirmNewTuitionAmount !== newTuitionAmount}
          >
            Submit
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
