/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import {
  Chip,
  Container,
  Fab,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Divider,
  Toolbar,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useRef, useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  SmartTypography, TableList,
} from '@think-zambia-foundation/core-lib/components';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import Loading from '../../components/Loading';
import PaymentReportFilterDialog from './PaymentReportDialog';
import TotalPaymentReport from './TotalPaymentReport';

import { TotalPaymentReportFilterProvider, useTotalPaymentReportFilter } from './TotalPaymentReportContext';

function TotalPaymentReportTable({ enrollments }) {
  const [paymentReportData, setPaymentReportReport] = useState();
  const {
    balanceFilter,
  } = useTotalPaymentReportFilter();

  useEffect(() => {
    if (enrollments) {
      if (!balanceFilter) {
        setPaymentReportReport(enrollments);
      } else {
        setPaymentReportReport(enrollments.filter((enrollment) => (enrollment.payments.reduce((accumulator, currentValue) => accumulator + currentValue.paymentAmount, 0)
          - enrollment.fees.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0)) !== 0));
      }
    }
  }, [enrollments, balanceFilter]);

  return (
    <TableList
      data={paymentReportData}
      tableMetadata={{
        href: '/student',
        tableKey: 'student.studentId',
        cells: [{
          key: 'name',
          header: 'Name',
          value(data) { return `${data.student.firstName} ${data.student.lastName}`; },
          variant: 'name',
        }, {
          key: 'grade',
          header: 'Grade',
          value(data) { return `${data.enrollmentClass.enrollmentGrade.enrollmentGradeType}`; },
          variant: 'name',
        },
        {
          key: 'enrollmentDate',
          header: 'Enrollment Date',
        }, {
          key: 'fees',
          header: 'Amount Due',
          value(data) {
            if (data.fees) {
              return `${data.fees.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0)}`;
            }
            return 0;
          },
          variant: 'name',
        }, {
          key: 'paymentAmount',
          header: 'Amount Paid',
          value(data) {
            if (data.payments) {
              return `${data.payments.reduce((accumulator, currentValue) => accumulator + currentValue.paymentAmount, 0)}`;
            }
            return 0;
          },
          variant: 'name',
        }, {
          key: 'outstanding',
          header: 'Outstanding balance',
          value(data) {
            let fees = 0;
            let payments = 0;
            let balance = null;
            if (data.fees) {
              fees = data.fees
                .reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0);
            }
            if (data.payments) {
              payments = data.payments
                .reduce((accumulator, currentValue) => accumulator + currentValue.paymentAmount, 0);
            }
            if (balanceFilter) {
              balance = balanceFilter.value;
            }
            return `${balance < (fees - payments) ? fees - payments : fees - payments}`;
          },
          variant: 'name',
        }],
      }}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
}));

function FilteredEnrollmentReport() {
  const {
    schoolsWithEnrollments,
    fetchingSchoolsWithEnrollments,
    fetchSchoolsAndEnrollments,
    selectedYear,
    selectedTerm,
  } = useKatanga();

  const {
    schoolFilter,
    setSchoolFilter,
    balanceFilter,
    setBalanceFilter,
  } = useTotalPaymentReportFilter();

  const [schools, setSchools] = useState();

  useEffect(() => {
    fetchSchoolsAndEnrollments(selectedYear, selectedTerm);
    // eslint-disable-next-line
  }, [selectedYear, selectedTerm]);

  useEffect(() => {
    if (schoolsWithEnrollments && schoolsWithEnrollments.schools) {
      setSchools(schoolsWithEnrollments.schools);
    }
  }, [schoolsWithEnrollments]);

  if (fetchingSchoolsWithEnrollments) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        style={{ height: '75vh' }}
      >
        <Loading />
      </Grid>
    );
  }
  if (!schools) {
    return <></>;
  }
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Grid item sm={12} xs={12}>
        <TotalPaymentReport />
      </Grid>
      <Grid item xs={12}>
        <Toolbar />
        <Divider light />
      </Grid>
      <Grid item xs={12}>
        {schoolFilter
          && (
            <Chip
              size="small"
              label={schoolFilter.name}
              onDelete={() => setSchoolFilter()}
              color="secondary"
              style={{ marginBottom: '2em' }}
            />
          )}
        {balanceFilter
          && (
            <Chip
              size="small"
              label={balanceFilter.label}
              onDelete={() => setBalanceFilter()}
              color="secondary"
              style={{ marginBottom: '2em' }}
            />
          )}
      </Grid>
      <Grid item xs={12}>
        {schools
          .filter((school) => !schoolFilter || school.schoolId === schoolFilter.schoolId)
          .map((school) => (
            <div key={school.schoolId} style={{ paddingTop: '2em', pageBreakAfter: 'always' }}>
              <Typography variant="h6" color="textSecondary">
                {`${school.name}`}
              </Typography>
              {school.enrollmentGrades && school.enrollmentGrades.map((enrollmentGrade) => (
                <React.Fragment key={enrollmentGrade.enrollmentGradeId}>
                  {enrollmentGrade.enrollmentClasses
                    && enrollmentGrade.enrollmentClasses.map((enrollmentClass) => (
                      <React.Fragment key={enrollmentClass.enrollmentClassId}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Grid item xs={12}>
                            {enrollmentClass.staff && (
                              <SmartTypography
                                text={`${enrollmentClass.staff.firstName} ${enrollmentClass.staff.lastName}`}
                                caption="Teacher"
                              />
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <TotalPaymentReportTable enrollments={enrollmentClass.enrollments} />
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    ))}
                </React.Fragment>
              ))}
            </div>
          ))}
      </Grid>
    </Grid>
  );
}

function PrintableTotalPaymentReport() {
  const {
    schoolsWithEnrollments,
  } = useKatanga();

  const {
    schoolFilter,
    balanceFilter,
  } = useTotalPaymentReportFilter();

  const [schools, setSchools] = useState();

  useEffect(() => {
    if (schoolsWithEnrollments && schoolsWithEnrollments.schools) {
      setSchools(schoolsWithEnrollments.schools);
    }
  }, [schoolsWithEnrollments, balanceFilter]);

  const calculatePaidAmount = (payments) => {
    if (payments === null) return 0;

    let totalPaid = 0;
    for (let i = 0; i < payments.length; i += 1) {
      totalPaid += payments[i].paymentAmount;
    }
    return totalPaid;
  };

  const calculateTotalFeeAmount = (fees) => {
    if (fees === null) return 0;

    let totalFees = 0;
    for (let i = 0; i < fees.length; i += 1) {
      totalFees += fees[i].amount;
    }
    return totalFees;
  };

  if (!schools) {
    return <></>;
  }
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Grid item xs={12}>
        {schools
          .filter((school) => !schoolFilter || school.schoolId === schoolFilter.schoolId)
          .map((school) => (
            <div key={school.schoolId} style={{ paddingTop: '2em', pageBreakAfter: 'always' }}>
              {school.enrollmentGrades && school.enrollmentGrades.map((enrollmentGrade) => (
                <React.Fragment key={enrollmentGrade.enrollmentGradeId}>
                  {enrollmentGrade.enrollmentClasses
                    && enrollmentGrade.enrollmentClasses.map((enrollmentClass) => (
                      <div
                        key={enrollmentClass.enrollmentClassId}
                        style={{
                          padding: '2em',
                          pageBreakAfter: 'always',
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Grid item xs={12}>
                            <Typography variant="h6" color="textSecondary">School</Typography>
                            <Typography variant="h5" gutterBottom>
                              {`${school.name}`}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h6" color="textSecondary">Class</Typography>
                            <Typography variant="h5" gutterBottom>
                              {enrollmentClass.enrollmentClassName}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {enrollmentClass.staff && (
                              <>
                                <Typography variant="h6" color="textSecondary">Staff</Typography>
                                <Typography variant="h5" gutterBottom>
                                  {`${enrollmentClass.staff.firstName} ${enrollmentClass.staff.lastName}`}
                                </Typography>
                              </>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Table
                              aria-labelledby="tableTitle"
                              size="medium"
                              aria-label="enhanced table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <Typography variant="body2" color="textSecondary"> Student Name</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="body2" color="textSecondary"> Tuition</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="body2" color="textSecondary"> Paid Amount</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="body2" color="textSecondary"> OutStanding Amount</Typography>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {enrollmentClass.enrollments
                                  && enrollmentClass.enrollments.filter((enrollment) => (balanceFilter ? (enrollment.fees.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0)
                                    - enrollment.payments.reduce((accumulator, currentValue) => accumulator + currentValue.paymentAmount, 0)) !== 0 : !balanceFilter))
                                    .map((enrollment) => (
                                      <React.Fragment key={enrollment.enrollmentId}>
                                        <TableRow
                                          hover
                                          key={enrollment.enrollmentDate}
                                        >
                                          <TableCell>
                                            <SmartTypography
                                              variant="name"
                                              text={`${enrollment.student.firstName} ${enrollment.student.lastName}`}
                                              gutterBottom={false}
                                            />
                                          </TableCell>
                                          <TableCell>
                                            {calculateTotalFeeAmount(enrollment.fees)}
                                          </TableCell>
                                          <TableCell>
                                            {calculatePaidAmount(enrollment.payments)}
                                          </TableCell>
                                          <TableCell>
                                            {calculateTotalFeeAmount(enrollment.fees) - calculatePaidAmount(enrollment.payments)}
                                          </TableCell>
                                        </TableRow>
                                      </React.Fragment>
                                    ))}
                              </TableBody>
                            </Table>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                </React.Fragment>
              ))}
            </div>
          ))}
      </Grid>
    </Grid>
  );
}

export class ComponentToPrint extends React.PureComponent {
  render() {
    return (
      <Container>
        <PrintableTotalPaymentReport />
      </Container>
    );
  }
}

export default function EnrollmentReport() {
  const classes = useStyles();
  const {
    setTotalPaymentReportFilterDialogOpen,
  } = useKatanga();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <TotalPaymentReportFilterProvider>
      <div style={{ display: 'none' }}><ComponentToPrint ref={componentRef} /></div>
      <FilteredEnrollmentReport />
      <PaymentReportFilterDialog />
      <div className={classes.fab}>
        <Grid container spacing={1}>
          <Grid item>
            <Fab
              color="secondary"
              onClick={handlePrint}
            >
              <PrintIcon />
            </Fab>
          </Grid>
          <Grid item>
            <Fab
              color="secondary"
              onClick={() => setTotalPaymentReportFilterDialogOpen(true)}
            >
              <FilterListIcon />
            </Fab>
          </Grid>
        </Grid>
      </div>
    </TotalPaymentReportFilterProvider>
  );
}
