/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import {
  Card,
  CardContent,
  Chip,
  Fab,
  Grid,
  ListItem,
  ListItemText,
  Paper,
  Switch,
  Toolbar,
  Typography,
  Stack as ChipStack,
  Grow,
} from '@mui/material';
import { TableList } from '@think-zambia-foundation/core-lib/components';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import React, {
  useEffect, useState,
} from 'react';
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Legend,
} from '@devexpress/dx-react-chart-bootstrap4';
import '@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css';
import { scaleBand } from '@devexpress/dx-chart-core';
import { ArgumentScale, Stack, Animation } from '@devexpress/dx-react-chart';
import PrintIcon from '@mui/icons-material/Print';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useHistory, useLocation } from 'react-router-dom';
import Loading from '../../components/Loading';
import { CapacityReportProvider, useCapacityReport } from './CapacityReportContext';
import CapacityReportFilterDialog from './CapacityReportFilterDialog';

const Root = (props) => (
  <Legend.Root
    {...props}
    className="m-auto flex-row"
    style={{
      listStyleType: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
  />
);

function CapacityChart({ capacityReportData }) {
  const [pupils, setPupils] = useState(true);
  const {
    selectedYear,
    selectedTerm,
  } = useKatanga();
  if (!capacityReportData) return <></>;
  return (
    <Paper square style={{ padding: '2em' }}>
      {!pupils && (
      <Chart
        data={capacityReportData}
      >
        <Typography
          variant="body2"
          align="center"
          color="secondary"
          style={{ textTransform: 'capitalize' }}
        >
          {`Income Capacity Report For Term ${selectedTerm}, ${selectedYear}`.toLowerCase()}
        </Typography>
        <br />
        <ArgumentScale factory={scaleBand} />
        <ArgumentAxis indentFromAxis={17} />
        <ValueAxis />
        <BarSeries
          name="Capacity"
          valueField="capacityAmount"
          argumentField="enrollmentClassName"
          color="#121b2a"
        />
        <BarSeries
          name="Enrolled"
          valueField="totalEnrollmentAmount"
          argumentField="enrollmentClassName"
          color="#354C61"
        />
        <BarSeries
          name="Paid"
          valueField="totalFeesPaid"
          argumentField="enrollmentClassName"
          color="#D8C095"
        />
        <Animation />
        <Legend position="right" rootComponent={Root} />
        <Stack />
      </Chart>
      )}
      {pupils && (
      <Chart
        data={capacityReportData}
      >
        <Typography
          variant="body2"
          align="center"
          color="secondary"
          style={{ textTransform: 'capitalize' }}
        >
          {`Pupil Capacity Report For Term ${selectedTerm}, ${selectedYear}`.toLowerCase()}
        </Typography>
        <br />
        <ArgumentAxis indentFromAxis={20} />
        <ValueAxis />
        <BarSeries
          name="Capacity"
          valueField="capacity"
          argumentField="enrollmentClassName"
          color="#354C61"
        />
        <BarSeries
          name="Enrolled"
          valueField="totalEnrollments"
          argumentField="enrollmentClassName"
          color="#D8C095"
        />
        <Animation />
        <Legend position="right" rootComponent={Root} />
        <Stack />
      </Chart>
      )}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography>Pupils</Typography>
        </Grid>
        <Grid item>
          <Switch
            value={pupils}
            onChange={() => {
              setPupils(!pupils);
            }}
          />
        </Grid>
        <Grid item>
          <Typography>Amounts</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

function CardTotal({
  label,
  value,
}) {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return (
    <ListItem button style={{ padding: '0.05em', margin: 0 }}>
      <ListItemText style={{ padding: '0.05em', margin: 0 }}>
        <Card square>
          <br />
          <CardContent>
            <Typography align="center" variant="body2" color="secondary">{label}</Typography>
            <Typography align="center" variant="h6">{numberWithCommas(value)}</Typography>
          </CardContent>
        </Card>
      </ListItemText>
    </ListItem>
  );
}

function CapacityReportTable({ capacityReportData }) {
  const [data, setData] = useState();
  useEffect(() => {
    if (capacityReportData) {
      setData(capacityReportData);
    }
  }, [capacityReportData]);

  // eslint-disable-next-line no-unused-vars
  const history = useHistory();

  function handleClick(enrollmentClassId) {
    const enrollmentClass = capacityReportData.find(
      (reportData) => reportData.enrollmentClassId === enrollmentClassId,
    );
    if (enrollmentClass) {
      history.push({
        pathname: '/report/payment/',
        search: `?schoolId=${enrollmentClass.schoolId}&enrollmentGradeId=${enrollmentClass.enrollmentGradeId}&enrollmentClassId=${enrollmentClass.enrollmentClassId}`,
      });
    }
  }

  if (!data) return <></>;
  return (
    <TableList
      hidePagination
      data={data}
      tableMetadata={{
        href: '/class',
        tableKey: 'enrollmentClassId',
        onClickCell: handleClick,
        cells: [{
          key: 'schoolId',
          header: 'School',
          value(d) { return `${d.school.name}`; },
          variant: 'id',
        }, {
          key: 'grade',
          header: 'Grade',
          value(d) { return `${d.enrollmentGrade.enrollmentGradeType}`; },
          variant: 'id',
        }, {
          key: 'name',
          header: 'Class',
          value(d) { return `${d.enrollmentClassName}`; },
          variant: 'id',
        }, {
          key: 'capacity',
          header: 'Capacity',
          value(d) { return `${d.capacity}`; },
          variant: 'name',
        }, {
          key: 'totalEnrollments',
          header: 'Enrolled',
          value(d) { return `${d.totalEnrollments}`; },
          variant: 'name',
        }, {
          key: 'capacityAmount',
          header: 'Cap. Amount',
          value(d) { return `${d.capacityAmount}`; },
          variant: 'name',
        }, {
          key: 'totalEnrollmentAmount',
          header: 'Enr. Amount',
          value(d) { return `${d.totalEnrollmentAmount}`; },
          variant: 'name',
        }, {
          key: 'totalFeesPaid',
          header: 'Paid Amount',
          value(d) { return `${d.totalFeesPaid}`; },
          variant: 'name',
        }],
      }}
    />
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function FilteredCapacityReport() {
  const query = useQuery();
  const [searchSchoolId, setSearchSchoolId] = useState();

  useEffect(() => {
    setSearchSchoolId(query.get('schoolId'));
  }, [query]);

  const {
    getCapacityReport,
    fetchingCapacityReport,
    fetchCapacityReportData,
    selectedYear,
    selectedTerm,
  } = useKatanga();

  const {
    capacityReportData,
    filteredCapacityReportData,
    setCapacityReportData,
    setCapacityReportFilterDialogOpen,
    setFilteredCapacityReportData,
  } = useCapacityReport();

  const [totalCapacity, setTotalCapacity] = useState();
  const [totalCapacityAmount, setTotalCapacityAmount] = useState();
  const [totalPaidAmount, setTotalPaidAmount] = useState();
  const [totalEnrolled, setTotalEnrolled] = useState();
  const [totalEnrolledAmount, setTotalEnrolledAmount] = useState();

  useEffect(() => {
    getCapacityReport({ term: selectedTerm, year: selectedYear });
  }, [selectedYear, selectedTerm]);

  function getTotalCapacity(capacityReport) {
    return capacityReport.reduce(
      (total, capacityReportItem) => total + capacityReportItem.capacity, 0,
    );
  }

  function getTotalCapacityAmount(capacityReport) {
    return capacityReport.reduce(
      (total, capacityReportItem) => total + capacityReportItem.capacityAmount, 0,
    );
  }

  function getTotalPaidAmount(capacityReport) {
    return capacityReport.reduce(
      (total, capacityReportItem) => total + capacityReportItem.totalFeesPaid, 0,
    );
  }

  function getTotalEnrolled(capacityReport) {
    return capacityReport.reduce(
      (total, capacityReportItem) => total + capacityReportItem.totalEnrollments, 0,
    );
  }

  function getTotalEnrolledAmount(capacityReport) {
    return capacityReport.reduce(
      (total, capacityReportItem) => total + capacityReportItem.totalEnrollmentAmount, 0,
    );
  }

  useEffect(() => {
    if (capacityReportData) {
      setFilteredCapacityReportData(
        capacityReportData.filter(
          (data) => !searchSchoolId || data.schoolId === searchSchoolId,
        ),
      );
    } else {
      setFilteredCapacityReportData();
    }
  }, [capacityReportData, searchSchoolId]);

  useEffect(() => {
    if (filteredCapacityReportData) {
      setTotalCapacity(getTotalCapacity(filteredCapacityReportData));
      setTotalCapacityAmount(getTotalCapacityAmount(filteredCapacityReportData));
      setTotalPaidAmount(getTotalPaidAmount(filteredCapacityReportData));
      setTotalEnrolled(getTotalEnrolled(filteredCapacityReportData));
      setTotalEnrolledAmount(getTotalEnrolledAmount(filteredCapacityReportData));
    }
  }, [filteredCapacityReportData]);

  useEffect(() => {
    if (fetchCapacityReportData && fetchCapacityReportData.capacityReport) {
      setCapacityReportData(fetchCapacityReportData.capacityReport);
    }
  }, [fetchCapacityReportData]);

  const history = useHistory();

  if (fetchingCapacityReport) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        style={{ height: '75vh' }}
      >
        <Loading />
      </Grid>
    );
  }
  if (!filteredCapacityReportData) {
    return <></>;
  }
  return (
    <>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        {searchSchoolId && (
        <Grid item xs={12}>
          <ChipStack direction="row" spacing={1}>
            <Grow in timeout={500}>
              <Chip
                variant="outlined"
                color="secondary"
                size="small"
                label={`School ID: ${searchSchoolId}`}
                onDelete={() => {
                  history.push({
                    search: '',
                  });
                }}
              />
            </Grow>
          </ChipStack>
          <br />
        </Grid>
        )}
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={4}>
              <CardTotal label="Total Capacity" value={`${totalCapacity}`} units="Pupils" />
            </Grid>
            <Grid item xs={4}>
              <CardTotal label="Total Capacity Amount" value={`${totalCapacityAmount}`} units="ZMW" />
            </Grid>
            <Grid item xs={4}>
              <CardTotal label="Total Paid Amount" value={`${totalPaidAmount}`} units="ZMW" />
            </Grid>
            <Grid item xs={4}>
              <CardTotal label="Total Enrolled" value={`${totalEnrolled}`} units="Pupils" />
            </Grid>
            <Grid item xs={4}>
              <CardTotal label="Total Enrolled Amount" value={`${totalEnrolledAmount}`} units="ZMW" />
            </Grid>
            <Grid item xs={4}>
              <CardTotal label="Total Outstanding Amount" value={`${totalEnrolledAmount - totalPaidAmount}`} units="ZMW" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12}>
          <Toolbar />
        </Grid>
        <Grid item xs={12}>
          <CapacityChart capacityReportData={filteredCapacityReportData} />
        </Grid>
        <Grid item xs={12}>
          <Toolbar />
        </Grid>
        <Grid item xs={12}>
          <CapacityReportTable capacityReportData={filteredCapacityReportData} />
        </Grid>
      </Grid>
      <div style={{
        position: 'fixed',
        bottom: '6.25em',
        right: '6.25em',
      }}
      >
        <Grid container spacing={1}>
          <Grid item>
            <Fab
              color="secondary"
              onClick={() => {}}
            >
              <PrintIcon />
            </Fab>
          </Grid>
          <Grid item>
            <Fab
              color="secondary"
              onClick={() => setCapacityReportFilterDialogOpen(true)}
            >
              <FilterListIcon />
            </Fab>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default function CapacityReport() {
  return (
    <CapacityReportProvider>
      <FilteredCapacityReport />
      <CapacityReportFilterDialog />
    </CapacityReportProvider>
  );
}
