import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import { useAuth, useCore } from '@think-zambia-foundation/core-lib/context';
import StudentDetailsToolbar from '../../components/StudentDetailsToolbar';
import StudentPaymentHistory from '../../components/StudentPaymentHistory';
import StudentEnrollmentInfo from '../../components/StudentEnrollmentInfo';
import StudentInfo from '../../components/StudentInfo';
import StudentContactInfo from '../../components/StudentContactInfo';
import Loading from '../../components/Loading';
import StudentEnrollmentSubjectReport from '../../components/StudentEnrollmentSubjectsList';

function StudentDetails() {
  const { studentId } = useParams();
  const {
    createEnrollmentData,
    fetchingStudentById,
    fetchStudentById,
    selectedTerm,
    selectedYear,
    student,
    setTitle,
    updateStudentData,
    updateEnrollmentData,
  } = useKatanga();

  const {
    addPaymentData,
  } = useCore();

  useEffect(() => {
    if (student) {
      setTitle(`${student.firstName} ${student.lastName}`);
    }
    // eslint-disable-next-line
  }, [student]);

  useEffect(() => {
    fetchStudentById(studentId, selectedTerm, selectedYear);
    // eslint-disable-next-line
  }, [studentId, selectedTerm, selectedYear, createEnrollmentData, addPaymentData]);

  useEffect(() => {
    if (updateStudentData && updateStudentData.updateStudent) {
      fetchStudentById(studentId, selectedTerm, selectedYear);
    }
  }, [updateStudentData]);

  useEffect(() => {
    if (updateEnrollmentData && updateEnrollmentData.updateEnrollment) {
      fetchStudentById(studentId, selectedTerm, selectedYear);
    }
  }, [updateEnrollmentData]);

  const { hasPermissionToClaim, currentUser } = useAuth();

  if (fetchingStudentById) return <Loading />;
  if (!student) return <></>;
  if (!hasPermissionToClaim('katangaStudentUser') && currentUser.uid !== student.uid) return <>You do not have permissions to this page. contact the system admin</>;
  if (student) {
    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <StudentDetailsToolbar />
          </Grid>
          <Grid item xs={12} sm={4}>
            <StudentInfo />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <StudentContactInfo />
              </Grid>
              <Grid item xs={12}>
                <StudentEnrollmentInfo />
              </Grid>
              {student && student.enrollment && student.enrollment.enrollmentSubjects && (
                <Grid item xs={12}>
                  <StudentEnrollmentSubjectReport />
                </Grid>
              )}
              <Grid item xs={12}>
                <StudentPaymentHistory />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default StudentDetails;
