/* eslint-disable react/jsx-props-no-spreading */
import { Grid, TextField, Autocomplete } from '@mui/material';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import KatangaDialog, { KatangaDialogProvider, useKatangaDialog } from '../../components/KatangaDialog/KatangaDialog';
import { useCapacityReport } from './CapacityReportContext';

function Wrapped() {
  const {
    capacityReportData,
    capacityReportFilterDialogOpen,
    setCapacityReportFilterDialogOpen,
  } = useCapacityReport();

  const {
    setCookie,
    setSelectedTerm,
    setSelectedYear,
  } = useKatanga();

  const [schools, setSchools] = useState();
  const [selectedSchoolFilter, setSelectedSchoolFilter] = useState();
  const [term, setTerm] = useState();
  const [year, setYear] = useState();
  const terms = ['ONE', 'TWO', 'THREE'];
  const currentYear = (new Date()).getFullYear();
  const years = Array.from(new Array(5), (val, index) => `${(currentYear - index)}`);

  const {
    setDialogOpen,
    setSubmitDisabled,
  } = useKatangaDialog();

  useEffect(() => {
    if (selectedSchoolFilter || term || year) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [selectedSchoolFilter, term, year]);

  useEffect(() => {
    if (capacityReportData) {
      const uniqueSchools = [];
      capacityReportData.forEach((row) => {
        if (!uniqueSchools.some((s) => s.schoolId === row.schoolId)) {
          uniqueSchools.push({ schoolId: row.schoolId, name: row.school.name });
        }
      });
      setSchools(uniqueSchools);
    }
  }, [capacityReportData]);

  const history = useHistory();

  useEffect(() => {
    setDialogOpen(capacityReportFilterDialogOpen);
  }, [capacityReportFilterDialogOpen]);

  return (
    <KatangaDialog
      modalTitle="Filter Capacity Report"
      submitAction={() => {
        if (term) {
          setSelectedTerm(term);
          setCookie('katangaSelectedTerm', term, { path: '/' });
        }
        if (year) {
          setSelectedYear(year);
          setCookie('katangaSelectedYear', year, { path: '/' });
        }
        if (selectedSchoolFilter) {
          history.push({
            search: `?schoolId=${selectedSchoolFilter.schoolId}`,
          });
          setSelectedSchoolFilter();
        }
        setCapacityReportFilterDialogOpen(false);
      }}
      onClose={() => setCapacityReportFilterDialogOpen(false)}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Autocomplete
            onChange={(event, value) => {
              setSelectedSchoolFilter(value);
            }}
            id="select-school-autocomplete"
            options={schools ?? []}
            getOptionLabel={(option) => (`${option.name}`)}
            noOptionsText="No school options: contact admin"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select School"
                variant="outlined"
                margin="normal"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            onChange={(e, value) => {
              setTerm(value);
            }}
            id="select-term-autocomplete"
            options={terms ?? []}
            value={term}
            disableClearable
            noOptionsText="No term options: contact admin"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Term"
                variant="outlined"
                margin="normal"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            onChange={(e, value) => {
              setYear(value);
            }}
            id="select-year-autocomplete"
            options={years ?? []}
            value={year}
            disableClearable
            noOptionsText="No year options: contact admin"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Year"
                variant="outlined"
                margin="normal"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </KatangaDialog>
  );
}

export default function CapacityReportFilterDialog() {
  return (
    <KatangaDialogProvider>
      <Wrapped />
    </KatangaDialogProvider>
  );
}
