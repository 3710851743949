/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { CollapsibleCard, SmartTypography } from '@think-zambia-foundation/core-lib/components';

export default function SchoolFeesCard({ title, referenceId, fees }) {
  const {
    school,
    setReferenceId,
    setAddFeesDialogOpen,
  } = useKatanga();

  const handleAddFeeBySchool = () => {
    if (school && referenceId) {
      setReferenceId(referenceId);
      setAddFeesDialogOpen(true);
    }
  };

  const [_school, setSchool] = useState();
  useEffect(() => {
    if (school) {
      setSchool(school);
    }
  }, [school]);

  if (!_school) return <></>;
  return (
    <CollapsibleCard
      title={title}
      cardActionCallback={handleAddFeeBySchool}
      icon={<AddTwoToneIcon />}
    >
      { fees && (
      <List dense>
        { fees.map((fee) => (
          <ListItem key={fee.feeId}>
            <ListItemText>
              <SmartTypography
                text={`ZMW ${fee.amount}`}
                caption={fee.feeType.toLowerCase() === 'other'
                  ? `${fee.feeType} (${fee.description})`
                  : `${fee.feeType}`}
                variant="name"
              />
            </ListItemText>
          </ListItem>
        ))}
      </List>
      )}
    </CollapsibleCard>
  );
}
