/* eslint-disable react/prop-types */
import {
  Chip, Container, Divider, Fab, Grid, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useRef, useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  SmartTypography, TableList,
} from '@think-zambia-foundation/core-lib/components';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import Loading from '../../components/Loading';
import EnrollmentReportFilterDialog from './EnrollmentReportDialog';

import { EnrollmentReportFilterProvider, useEnrollmentReportFilter } from './EnrollmentReportContext';

function EnrollmentReportTable({ enrollments }) {
  if (!enrollments) return <></>;
  return (
    <>
      <TableList
        data={enrollments}
        hidePagination
        tableMetadata={{
          href: '/student',
          tableKey: 'student.studentId',
          cells: [{
            key: 'name',
            header: 'Name',
            value(data) { return `${data.student.firstName} ${data.student.lastName}`; },
            variant: 'name',
          }, {
            key: 'grade',
            header: 'Grade',
            value(data) { return `${data.enrollmentClass.enrollmentGrade.enrollmentGradeType}`; },
            variant: 'name',
          }, {
            key: 'class',
            header: 'Class',
            value(data) { return `${data.enrollmentClass.enrollmentClassName}`; },
            variant: 'name',
          }, {
            key: 'year',
            header: 'Year',
            variant: 'name',
          }, {
            key: 'term',
            header: 'Term',
            variant: 'name',
          }, {
            key: 'enrollmentDate',
            header: 'Enrollment Date',
          }],
        }}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
}));

function FilteredEnrollmentReport() {
  const {
    schoolsWithEnrollments,
    fetchingSchoolsWithEnrollments,
    fetchSchoolsAndEnrollments,
    selectedYear,
    selectedTerm,
  } = useKatanga();

  const {
    schoolFilter,
    setSchoolFilter,
  } = useEnrollmentReportFilter();

  const [schools, setSchools] = useState();

  useEffect(() => {
    setSchools();
    fetchSchoolsAndEnrollments(selectedYear, selectedTerm);
    // eslint-disable-next-line
  }, [selectedYear, selectedTerm]);

  useEffect(() => {
    if (schoolsWithEnrollments && schoolsWithEnrollments.schools) {
      setSchools(schoolsWithEnrollments.schools);
    }
  }, [schoolsWithEnrollments]);

  if (fetchingSchoolsWithEnrollments) {
    return (
      <Loading />
    );
  }
  if (!schools) {
    return <></>;
  }
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Grid item xs={12}>
        {schoolFilter
        && (
        <Chip
          size="small"
          label={schoolFilter.name}
          onDelete={() => setSchoolFilter()}
          color="secondary"
          style={{ marginBottom: '2em' }}
        />
        )}
      </Grid>
      <Grid item xs={12}>
        {schools
          .filter((school) => !schoolFilter || school.schoolId === schoolFilter.schoolId)
          .map((school) => (
            <div key={school.schoolId} style={{ paddingTop: '2em', pageBreakAfter: 'always' }}>
              <Typography variant="h6" color="textSecondary">
                {`${school.name}`}
              </Typography>
              {school.enrollmentGrades && school.enrollmentGrades.map((enrollmentGrade) => (
                <React.Fragment key={enrollmentGrade.enrollmentGradeId}>
                  {enrollmentGrade.enrollmentClasses
                && enrollmentGrade.enrollmentClasses.map((enrollmentClass) => (
                  <React.Fragment key={enrollmentClass.enrollmentClassId}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        { enrollmentClass.staff && (
                        <SmartTypography
                          text={`${enrollmentClass.staff.firstName} ${enrollmentClass.staff.lastName}`}
                          caption="Teacher"
                        />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <EnrollmentReportTable enrollments={enrollmentClass.enrollments} />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))}
                </React.Fragment>
              ))}
            </div>
          ))}
      </Grid>
    </Grid>
  );
}

function PrintableEnrollmentReport() {
  const {
    schoolsWithEnrollments,
  } = useKatanga();

  const {
    schoolFilter,
  } = useEnrollmentReportFilter();

  const [schools, setSchools] = useState();

  useEffect(() => {
    if (schoolsWithEnrollments && schoolsWithEnrollments.schools) {
      setSchools(schoolsWithEnrollments.schools);
    }
  }, [schoolsWithEnrollments]);

  if (!schools) {
    return <></>;
  }
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Grid item xs={12}>
        {schools
          .filter((school) => !schoolFilter || school.schoolId === schoolFilter.schoolId)
          .map((school) => (
            <div key={school.schoolId} style={{ paddingTop: '2em', pageBreakAfter: 'always' }}>
              {school.enrollmentGrades && school.enrollmentGrades.map((enrollmentGrade) => (
                <React.Fragment key={enrollmentGrade.enrollmentGradeId}>
                  {enrollmentGrade.enrollmentClasses
                && enrollmentGrade.enrollmentClasses.map((enrollmentClass) => (
                  <div
                    key={enrollmentClass.enrollmentClassId}
                    style={{
                      padding: '2em',
                      pageBreakAfter: 'always',
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        <Typography variant="h6" color="textSecondary">School</Typography>
                        <Typography variant="h5" gutterBottom>
                          {`${school.name}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" color="textSecondary">Class</Typography>
                        <Typography variant="h5" gutterBottom>
                          {enrollmentClass.enrollmentClassName}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        { enrollmentClass.staff && (
                          <>
                            <Typography variant="h6" color="textSecondary">Teacher</Typography>
                            <Typography variant="h5" gutterBottom>
                              {`${enrollmentClass.staff.firstName} ${enrollmentClass.staff.lastName}`}
                            </Typography>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider variant="inset" style={{ marginBottom: '1em' }} />
                        <Typography variant="h6" color="textSecondary" gutterBottom>Students</Typography>
                      </Grid>
                      {enrollmentClass.enrollments
                      && enrollmentClass.enrollments.map((enrollment) => (
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            {`${enrollment.student.firstName} ${enrollment.student.lastName}`}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                ))}
                </React.Fragment>
              ))}
            </div>
          ))}
      </Grid>
    </Grid>
  );
}

export class ComponentToPrint extends React.PureComponent {
  render() {
    return (
      <Container>
        <PrintableEnrollmentReport />
      </Container>
    );
  }
}

export default function EnrollmentReport() {
  const classes = useStyles();
  const {
    setEnrollmentReportFilterDialogOpen,
  } = useKatanga();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <EnrollmentReportFilterProvider>
      <div style={{ display: 'none' }}><ComponentToPrint ref={componentRef} /></div>
      <FilteredEnrollmentReport />
      <EnrollmentReportFilterDialog />
      <div className={classes.fab}>
        <Grid container spacing={1}>
          <Grid item>
            <Fab
              color="secondary"
              onClick={handlePrint}
            >
              <PrintIcon />
            </Fab>
          </Grid>
          <Grid item>
            <Fab
              color="secondary"
              onClick={() => setEnrollmentReportFilterDialogOpen(true)}
            >
              <FilterListIcon />
            </Fab>
          </Grid>
        </Grid>
      </div>
    </EnrollmentReportFilterProvider>
  );
}
