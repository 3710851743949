import { createTheme } from '@mui/material/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Katanga-Medium',
      'Roboto',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#121b2a',
      dark: '#121b2a',
      light: '#354C61',
    },
    secondary: {
      main: '#C7A567',
      dark: '#D8C095',
      light: '#D8C095',
    },
    info: {
      main: '#808080',
    },
  },
});

export default theme;
