/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import {
  Card,
  CardContent,
  Chip,
  Fab,
  Grid,
  Grow,
  Hidden,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { TableList } from '@think-zambia-foundation/core-lib/components';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import React, {
  useEffect, useState,
} from 'react';
import {
  Chart,
  PieSeries,
  Legend,
} from '@devexpress/dx-react-chart-bootstrap4';
import '@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css';
import { Animation, Palette } from '@devexpress/dx-react-chart';
import PrintIcon from '@mui/icons-material/Print';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useHistory, useLocation } from 'react-router-dom';
import Loading from '../../components/Loading';
import { PaymentReportProvider, usePaymentReport } from './PaymentReportV2Context';
import PaymentReportV2FilterDialog from './PaymentReportV2FilterDialog';

const Root = (props) => (
  <Legend.Root
    {...props}
    className="m-auto flex-row"
    style={{
      listStyleType: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
  />
);

function PaymentChart({ totalAmountPaid, balance }) {
  return (
    <div style={{ padding: '2em' }}>
      <Chart
        data={[{
          key: 'Paid',
          val: totalAmountPaid || 0,
        }, {
          key: 'Balance',
          val: balance || 0,
        }]}
      >
        <Palette scheme={['#354C61', '#D8C095']} />
        <PieSeries
          valueField="val"
          argumentField="key"
          innerRadius={0.9}
          palette="Bright"
        />
        <Legend position="right" rootComponent={Root} />
        <Animation />
      </Chart>
    </div>
  );
}

function CardTotal({
  label,
  value,
}) {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return (
    <ListItem button style={{ padding: '0.05em', margin: 0 }}>
      <ListItemText style={{ padding: '0.05em', margin: 0 }}>
        <Card square>
          <br />
          <CardContent>
            <Typography align="center" variant="body2" color="secondary">{label}</Typography>
            <Typography align="center" variant="h6">{numberWithCommas(value)}</Typography>
          </CardContent>
        </Card>
      </ListItemText>
    </ListItem>
  );
}

function PaymentReportTable({ paymentReportData }) {
  const [data, setData] = useState();
  useEffect(() => {
    if (paymentReportData) {
      setData(paymentReportData);
    }
  }, [paymentReportData]);

  // eslint-disable-next-line no-unused-vars
  const history = useHistory();

  if (!paymentReportData) return <></>;
  return (
    <TableList
      hidePagination
      data={data}
      tableMetadata={{
        href: '/student',
        tableKey: 'studentId',
        cells: [{
          key: 'studentName',
          header: 'Student',
          variant: 'id',
        }, {
          key: 'enrollmentClassName',
          header: 'Class',
          variant: 'id',
        }, {
          key: 'enrollmentDate',
          header: 'Enrollment Date',
          value(d) { return `${d.enrollmentDate}`; },
          variant: 'name',
        }, {
          key: 'totalAmountDue',
          header: 'Total Due',
          variant: 'name',
        }, {
          key: 'totalFeesPaid',
          header: 'Total Paid',
          variant: 'name',
        }, {
          key: 'totalBalance',
          header: 'Balance',
          value(d) { return `${d.totalAmountDue - d.totalFeesPaid}`; },
          variant: 'name',
        }],
      }}
    />
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function FilteredPaymentReport() {
  const query = useQuery();
  const [searchEnrollmentClassId, setSearchEnrollmentClassId] = useState();

  useEffect(() => {
    setSearchEnrollmentClassId(query.get('enrollmentClassId'));
  }, [query]);

  const {
    getPaymentReport,
    fetchingPaymentReport,
    fetchPaymentReportData,
    selectedYear,
    selectedTerm,
  } = useKatanga();

  const {
    paymentReportData,
    filteredPaymentReportData,
    setPaymentReportData,
    setPaymentReportV2FilterDialogOpen,
    setFilteredPaymentReportData,
  } = usePaymentReport();

  const [totalAmountDue, setTotalAmountDue] = useState();
  const [totalAmountPaid, setTotalAmountPaid] = useState();

  useEffect(() => {
    getPaymentReport({ term: selectedTerm, year: selectedYear });
  }, [selectedYear, selectedTerm]);

  function getTotalAmountDue(paymentReport) {
    return paymentReport.reduce(
      (total, paymentReportItem) => total + paymentReportItem.totalAmountDue, 0,
    );
  }

  function getTotalAmountPaid(paymentReport) {
    return paymentReport.reduce(
      (total, paymentReportItem) => total + paymentReportItem.totalFeesPaid, 0,
    );
  }

  useEffect(() => {
    if (paymentReportData) {
      setFilteredPaymentReportData(
        paymentReportData.filter(
          (data) => !searchEnrollmentClassId
          || data.enrollmentClassId === searchEnrollmentClassId,
        ),
      );
    } else {
      setFilteredPaymentReportData();
    }
  }, [paymentReportData, searchEnrollmentClassId]);

  useEffect(() => {
    if (filteredPaymentReportData) {
      setTotalAmountDue(getTotalAmountDue(filteredPaymentReportData));
      setTotalAmountPaid(getTotalAmountPaid(filteredPaymentReportData));
    }
  }, [filteredPaymentReportData]);

  useEffect(() => {
    if (fetchPaymentReportData && fetchPaymentReportData.paymentReport) {
      setPaymentReportData(fetchPaymentReportData.paymentReport);
    }
  }, [fetchPaymentReportData]);

  const history = useHistory();

  if (fetchingPaymentReport) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        style={{ height: '75vh' }}
      >
        <Loading />
      </Grid>
    );
  }
  if (!filteredPaymentReportData) {
    return <></>;
  }
  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item sm={6} xs={12}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={6}>
              <CardTotal label="Total Due" value={`${totalAmountDue}`} units="ZMW" />
            </Grid>
            <Grid item xs={6}>
              <CardTotal label="Total Paid" value={`${totalAmountPaid}`} units="ZMW" />
            </Grid>
            <Grid item xs={6}>
              <CardTotal label="Total Balance" value={`${totalAmountDue - totalAmountPaid}`} units="ZMW" />
            </Grid>
            <Grid item xs={6}>
              <CardTotal label="% Paid" value={`${Math.floor((totalAmountPaid * 100) / totalAmountDue)}`} units="Percent" />
            </Grid>
          </Grid>
        </Grid>
        <Hidden only="xs">
          <Grid item sm={5}>
            <PaymentChart
              totalAmountPaid={totalAmountPaid}
              balance={totalAmountDue - totalAmountPaid}
            />
          </Grid>
        </Hidden>
        {searchEnrollmentClassId && (
        <Grid item xs={12}>
          <Stack direction="row" spacing={1}>
            <Grow in timeout={500}>
              <Chip
                variant="outlined"
                color="secondary"
                size="small"
                label={`Class ID: ${searchEnrollmentClassId}`}
                onDelete={() => {
                  history.push({
                    search: '',
                  });
                }}
              />
            </Grow>
          </Stack>
          <br />
        </Grid>
        )}
        <Grid item xs={12}>
          <PaymentReportTable paymentReportData={filteredPaymentReportData} />
        </Grid>
      </Grid>
      <div style={{
        position: 'fixed',
        bottom: '6.25em',
        right: '6.25em',
      }}
      >
        <Grid container spacing={1}>
          <Grid item>
            <Fab
              color="secondary"
              onClick={() => {}}
            >
              <PrintIcon />
            </Fab>
          </Grid>
          <Grid item>
            <Fab
              color="secondary"
              onClick={() => setPaymentReportV2FilterDialogOpen(true)}
            >
              <FilterListIcon />
            </Fab>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default function PaymentReport() {
  return (
    <PaymentReportProvider>
      <FilteredPaymentReport />
      <PaymentReportV2FilterDialog />
    </PaymentReportProvider>
  );
}
