/*
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';

import { useAuth } from '@think-zambia-foundation/core-lib/context';
import KatangaWrapper from '../Common/KatangaWrapper';

function StudentPaymentHistory() {

        <TableContainer>
          <Table className={classes.table} aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">Invoice #</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="caption" color="textSecondary">Paid By</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="caption" color="textSecondary">Received By</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="caption" color="textSecondary">Date</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="caption" color="textSecondary">Type</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="caption" color="textSecondary">Amount</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {student.enrollment.payments && student.enrollment.payments.map((payment) => (
                <TableRow key={payment.paymentId}>
                  <TableCell>{payment.paymentInvoice}</TableCell>
                  <TableCell align="right">{payment.paymentMadeBy}</TableCell>
                  <TableCell align="right">{payment.paymentReceivedBy}</TableCell>
                  <TableCell align="right">{payment.paymentDate}</TableCell>
                  <TableCell align="right">{payment.note}</TableCell>
                  <TableCell align="right">{`ZMW ${payment.paymentAmount}`}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell rowSpan={4} colSpan={4} />
                <TableCell align="right" colSpan={1} variant="footer">Fees Due</TableCell>
                <TableCell align="right" variant="footer">
                  {student.enrollment.tuition
                    ? `ZMW ${student.enrollment.fees.reduce(feesReducer, 0).toFixed(2)}`
                    : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right" colSpan={1} variant="footer">Total Paid</TableCell>
                <TableCell variant="footer" align="right">
                  {`ZMW ${
                    student.enrollment.payments
                      ? student.enrollment.payments.reduce(paymentReducer, 0).toFixed(2)
                      : 0}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right" colSpan={1} variant="footer">Balance</TableCell>
                <TableCell align="right" variant="footer">
                  {student.enrollment.tuition
                    ? `ZMW ${student.enrollment.fees.reduce(feesReducer, 0).toFixed(2)
                  - (
                    student.enrollment.payments
                      ? student.enrollment.payments.reduce(paymentReducer, 0).toFixed(2)
                      : 0
                  )}` : 'ZMW 0'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </KatangaWrapper>
    </>
  );
}
*/

import React from 'react';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import { PaymentHistoryCard } from '@think-zambia-foundation/core-lib/components';

export default function StudentPaymentHistory() {
  const {
    student,
  } = useKatanga();

  if (!student || !student.enrollment) return <></>;
  return (
    <PaymentHistoryCard
      addPaymentOptions={student.enrollment.fees.map((fee) => ({
        option: fee.feeType.toLowerCase() === 'other'
          ? `${fee.feeType} (${fee.description})`
          : `${fee.feeType}`,
        amount: fee.amount,
      }))}
      referenceId={student.enrollment.enrollmentId}
      addPaymentClaim="katangaPaymentAdmin"
    />
  );
}
