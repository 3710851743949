import React, { useEffect } from 'react';
import {
  Grid, TextField, Typography,
} from '@mui/material';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import Autocomplete from '@mui/material/Autocomplete';
import Loading from '../Loading';
import KatangaDialog, { KatangaDialogProvider, useKatangaDialog } from '../KatangaDialog/KatangaDialog';

// eslint-disable-next-line react/prop-types
function Wrapped() {
  const {
    updateEnrollmentSubjects,
    updateEnrollmentSubjectData,
    updateEnrollmentSubjectDialogOpen,
    updatingEnrollmentSubject,
    errorUpdatingEnrollmentSubject,
    setUpdateEnrollmentSubjectDialogOpen,
    updatedScore,
    setUpdatedScore,
    updateEnrollmentSubjectId,
  } = useKatanga();

  const {
    setDialogOpen,
    setSubmitDisabled,
  } = useKatangaDialog();

  useEffect(() => {
    if (updatedScore) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
    // eslint-disable-next-line
  }, [updatedScore]);

  useEffect(() => {
    setDialogOpen(updateEnrollmentSubjectDialogOpen);
    // eslint-disable-next-line
  }, [updateEnrollmentSubjectDialogOpen]);

  useEffect(() => {
    if (updateEnrollmentSubjectData) {
      setUpdateEnrollmentSubjectDialogOpen(false);
    }
    // eslint-disable-next-line
  }, [updateEnrollmentSubjectData]);

  const handleSubmitUpdateEnrollmentSubject = () => {
    updateEnrollmentSubjects(
      updateEnrollmentSubjectId,
      updatedScore,
    );
  };

  if (updatingEnrollmentSubject) return <Loading />;
  return (
    <KatangaDialog
      modalTitle="Update score"
      submitAction={() => handleSubmitUpdateEnrollmentSubject()}
      onClose={() => setUpdateEnrollmentSubjectDialogOpen(false)}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12}>
          {errorUpdatingEnrollmentSubject && (
            <Typography variant="body2" color="error">{errorUpdatingEnrollmentSubject}</Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12}>
          <Autocomplete
            id="Score"
            fullWidth
            onChange={(event, value) => setUpdatedScore(parseInt(value, 10))}
            options={Array.from(Array(100).keys()).map((number) => `${number}`)}
            noOptionsText="between 0 and 100"
            style={{ width: 250 }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderInput={(params) => <TextField {...params} fullWidth label="Enter Score" variant="outlined" />}
          />
        </Grid>
      </Grid>
    </KatangaDialog>
  );
}

export default function UpdateEnrollmentSubjectDialog(
) {
  return (
    <KatangaDialogProvider>
      <Wrapped />
    </KatangaDialogProvider>
  );
}
