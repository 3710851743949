/* eslint-disable react/prop-types */
import {
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import React from 'react';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import { CollapsibleCard, SmartTypography } from '@think-zambia-foundation/core-lib/components';

export default function SubjectsCard({ subjects, referenceId, title }) {
  const {
    setAddSubjectDialogOpen,
    setReferenceId,
  } = useKatanga();

  function setUpdateSubjectDialogOpen() {
    // open update subject dialog
  }

  const handleClickAddSubject = () => {
    setReferenceId(referenceId);
    setAddSubjectDialogOpen(true);
  };

  return (
    <CollapsibleCard
      title={title}
      cardActionCallback={handleClickAddSubject}
      icon={<AddTwoToneIcon />}
    >
      {subjects && (
      <List dense>
        {subjects.map((subject) => (
          <ListItem
            key={subject.subjectId}
            hover
            button
            onClick={() => setUpdateSubjectDialogOpen(true)}
          >
            <ListItemText>
              <SmartTypography
                caption={`${subject.staff.firstName} ${subject.staff.lastName}`}
                text={subject.name}
                variant="name"
              />
            </ListItemText>
          </ListItem>
        ))}
      </List>
      )}
    </CollapsibleCard>
  );
}
