/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  FormControl, Grid, TextField, Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import { useCore } from '@think-zambia-foundation/core-lib/context';
import KatangaDialog, {
  KatangaDialogProvider,
  useKatangaDialog,
} from '../KatangaDialog/KatangaDialog';
import Loading from '../Loading';

// eslint-disable-next-line react/prop-types
function Wrapped() {
  const [errorMessage, setErrorMessage] = useState();
  const [subjectName, setSubjectName] = useState();
  const [selectedStaffId, setSelectedStaffId] = useState();

  const {
    addingSubject,
    addSubject,
    addSubjectDialogOpen,
    referenceId,
    setReferenceId,
    setAddSubjectDialogOpen,
    successAddingSubject,
  } = useKatanga();

  const {
    setDialogOpen,
    setSubmitDisabled,
  } = useKatangaDialog();

  useEffect(() => {
    if (referenceId
      && subjectName
      && selectedStaffId) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [referenceId, subjectName, selectedStaffId]);

  useEffect(() => {
    setDialogOpen(addSubjectDialogOpen);
  }, [addSubjectDialogOpen]);

  const handleSubmitAddSubject = () => {
    addSubject(
      referenceId,
      subjectName,
      selectedStaffId,
    );
  };

  const {
    errorFetchingStaffList,
    fetchStaff,
    fetchStaffListData,
  } = useCore();

  useEffect(() => {
    fetchStaff();
  }, []);

  useEffect(() => {
    if (successAddingSubject) {
      setReferenceId();
      setAddSubjectDialogOpen(false);
    }
  }, [successAddingSubject]);

  useEffect(() => {
    if (errorFetchingStaffList) {
      setErrorMessage('error fetching staff list');
    }
  }, [errorFetchingStaffList]);

  if (addingSubject) return <Loading />;
  return (
    <KatangaDialog
      modalTitle="Add Subject"
      submitAction={() => handleSubmitAddSubject()}
      onClose={() => {
        setReferenceId();
        setAddSubjectDialogOpen(false);
      }}
    >
      <FormControl>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            {errorMessage && (
            <Typography variant="body2" color="error">
              {errorMessage}
            </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="normal"
              label="Subject Name"
              id="subject name"
              onChange={(event) => setSubjectName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              onChange={(event, value) => setSelectedStaffId(value.staffId)}
              id="select-staff-autocomplete"
              options={fetchStaffListData
                    && fetchStaffListData.staffList ? fetchStaffListData.staffList : []}
              getOptionLabel={(option) => (`${option.firstName} ${option.lastName}`)}
              noOptionsText="No staff options: contact admin"
              renderInput={(params) => <TextField {...params} label="Select Teacher" variant="outlined" />}
            />
          </Grid>
        </Grid>
      </FormControl>
    </KatangaDialog>
  );
}

// eslint-disable-next-line react/prop-types
export default function AddSubjectDialog() {
  return (
    <KatangaDialogProvider>
      <Wrapped />
    </KatangaDialogProvider>
  );
}
