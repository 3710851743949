import React, {
  useState, createContext, useContext,
} from 'react';

const PaymentReportV2Context = createContext();

export function usePaymentReport() {
  return useContext(PaymentReportV2Context);
}

// eslint-disable-next-line react/prop-types
export function PaymentReportProvider({ children }) {
  const [paymentReportData, setPaymentReportData] = useState();
  const [filteredPaymentReportData, setFilteredPaymentReportData] = useState();
  const [paymentReportFilterDialogOpen, setPaymentReportV2FilterDialogOpen] = useState(false);
  const [enrollmentClassFilter, setEnrollmentClassFilter] = useState();
  const [gradeFilter, setGradeFilter] = useState();
  const [schoolFilter, setSchoolFilter] = useState();

  return (
    <PaymentReportV2Context.Provider
      value={{
        paymentReportData,
        paymentReportFilterDialogOpen,
        enrollmentClassFilter,
        filteredPaymentReportData,
        gradeFilter,
        schoolFilter,
        setPaymentReportData,
        setPaymentReportV2FilterDialogOpen,
        setEnrollmentClassFilter,
        setFilteredPaymentReportData,
        setGradeFilter,
        setSchoolFilter,
      }}
    >
      {children}
    </PaymentReportV2Context.Provider>
  );
}
