/* eslint-disable react/jsx-props-no-spreading */
import { TextField, Autocomplete } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import SelectTermAndYear from '../../components/FormControl/SelectTermAndYear';
import KatangaDialog, { KatangaDialogProvider, useKatangaDialog } from '../../components/KatangaDialog/KatangaDialog';
import Loading from '../../components/Loading';
import { useEnrollmentReportFilter } from './EnrollmentReportContext';

function Wrapped() {
  const {
    enrollmentReportFilterDialogOpen,
    fetchingSchools,
    fetchSchools,
    fetchSchoolsData,
    setEnrollmentReportFilterDialogOpen,
  } = useKatanga();

  const [schools, setSchools] = useState();

  const {
    setDialogOpen,
    setSubmitDisabled,
  } = useKatangaDialog();

  const {
    setSchoolFilter,
  } = useEnrollmentReportFilter();

  useEffect(() => {
    setDialogOpen(enrollmentReportFilterDialogOpen);
    setSubmitDisabled(false);
    fetchSchools();
    // eslint-disable-next-line
  }, [enrollmentReportFilterDialogOpen]);

  useEffect(() => {
    if (fetchSchoolsData) {
      setSchools(fetchSchoolsData.schools);
    }
  }, [fetchSchoolsData]);

  if (fetchingSchools) return <Loading />;
  return (
    <KatangaDialog
      modalTitle="Filter Enrollment Report"
      submitAction={() => setEnrollmentReportFilterDialogOpen(false)}
      onClose={() => setEnrollmentReportFilterDialogOpen(false)}
    >
      <Autocomplete
        onChange={(event, value) => setSchoolFilter(value)}
        id="select-school-autocomplete"
        options={schools ?? []}
        getOptionLabel={(option) => (`${option.name}`)}
        noOptionsText="No school options: contact admin"
        style={{ width: 300, marginBottom: '2em' }}
        renderInput={(params) => <TextField {...params} label="Select School" variant="outlined" />}
      />
      <SelectTermAndYear />
    </KatangaDialog>
  );
}

export default function EnrollmentReportFilterDialog() {
  return (
    <KatangaDialogProvider>
      <Wrapped />
    </KatangaDialogProvider>
  );
}
