/* eslint-disable react/jsx-props-no-spreading */
import { TextField, Autocomplete } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import KatangaDialog, { KatangaDialogProvider, useKatangaDialog } from '../KatangaDialog/KatangaDialog';

const StatusOptions = [
  'ACTIVE',
  'SUSPENDED',
];

function Wrapped() {
  const [status, setStatus] = useState();

  const {
    studentListFilterDialogOpen,
    setStudentListFilterDialogOpen,
    setStudentStatus,
    studentStatus,
  } = useKatanga();

  const {
    setDialogOpen,
    setSubmitDisabled,
  } = useKatangaDialog();

  useEffect(() => {
    if (studentStatus) {
      setStatus(studentStatus);
    }
  }, [studentStatus]);

  useEffect(() => {
    setDialogOpen(studentListFilterDialogOpen);
    // eslint-disable-next-line
  }, [studentListFilterDialogOpen]);

  useEffect(() => {
    if (status) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
    // eslint-disable-next-line
  }, [status]);

  return (
    <KatangaDialog
      modalTitle="Filter Student List"
      submitAction={() => {
        setStudentStatus(status);
        setStudentListFilterDialogOpen(false);
      }}
      onClose={() => {
        setStudentListFilterDialogOpen(false);
      }}
    >
      <Autocomplete
        onChange={(event, value) => setStatus(value)}
        id="select-studentStatus-autocomplete"
        options={StatusOptions || ''}
        value={status || ''}
        noOptionsText="No Student Status options: contact admin"
        style={{ width: 300, marginBottom: '2em' }}
        renderInput={(params) => <TextField {...params} label="Select Student Status" variant="outlined" />}
      />
    </KatangaDialog>
  );
}

export default function StudentListFilterDialog() {
  return (
    <KatangaDialogProvider>
      <Wrapped />
    </KatangaDialogProvider>
  );
}
