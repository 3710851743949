import {
  Grid,
  Typography,
  TextField,
  Container,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, {
  useEffect, useState,
} from 'react';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import KatangaDialog, { KatangaDialogProvider, useKatangaDialog } from '../KatangaDialog/KatangaDialog';
import Loading from '../Loading';

// eslint-disable-next-line react/prop-types
function Wrapped({ enrollmentId }) {
  const {
    updateEnrollment,
    updateEnrollmentData,
    updateEnrollmentDialogOpen,
    creatingEnrollment,
    setUpdateEnrollmentDialogOpen,
    fetchSchools,
    fetchingSchools,
    errorFetchingSchools,
    fetchSchoolsData,
  } = useKatanga();

  const {
    setDialogOpen,
    setSubmitDisabled,
  } = useKatangaDialog();

  useEffect(() => {
    setDialogOpen(updateEnrollmentDialogOpen);
    // eslint-disable-next-line
  }, [updateEnrollmentDialogOpen]);

  const [schools, setSchools] = useState();
  const [enrollmentGrades, setEnrollmentGrades] = useState();
  const [enrollmentClasses, setEnrollmentClasses] = useState();

  const [errorMessage, setErrorMessage] = useState();
  const [selectedSchool, setSelectedSchool] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('');
  const [selectedEnrollmentClass, setSelectedEnrollmentClass] = useState('');

  useEffect(() => {
    if (enrollmentId && selectedSchool && selectedGrade && selectedEnrollmentClass) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
    // eslint-disable-next-line
  }, [selectedSchool, selectedGrade, selectedEnrollmentClass]);

  function handleSubmitUpdateEnrollment() {
    updateEnrollment({
      enrollmentId,
      enrollmentClassId: selectedEnrollmentClass.enrollmentClassId,
    });
  }

  useEffect(() => {
    setErrorMessage();
  }, [selectedEnrollmentClass]);

  useEffect(() => {
    setSelectedEnrollmentClass();
    if (selectedGrade) {
      if (selectedGrade.enrollmentClasses) {
        setEnrollmentClasses(selectedGrade.enrollmentClasses);
        setErrorMessage();
      } else {
        setErrorMessage(`No available classes for grade ${selectedGrade.enrollmentGradeType} at ${selectedSchool.name}`);
      }
    }
    // eslint-disable-next-line
  }, [selectedGrade]);

  useEffect(() => {
    setSelectedGrade();
    if (selectedSchool) {
      if (selectedSchool.enrollmentGrades) {
        setEnrollmentGrades(selectedSchool.enrollmentGrades);
        setErrorMessage();
      } else {
        setErrorMessage(`No available grades for school ${selectedSchool.name}`);
      }
    }
  }, [selectedSchool]);

  useEffect(() => {
    if (fetchSchoolsData && fetchSchoolsData.schools) {
      setSchools(fetchSchoolsData.schools);
    }
  }, [fetchingSchools, errorFetchingSchools, fetchSchoolsData]);

  useEffect(() => {
    fetchSchools();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (updateEnrollmentData) {
      setUpdateEnrollmentDialogOpen(false);
    }
    // eslint-disable-next-line
  }, [updateEnrollmentData]);

  if (creatingEnrollment || fetchingSchools) return <Loading />;
  if (!schools) return <></>;
  return (
    <>
      <KatangaDialog
        modalTitle="Update Enrollment"
        submitAction={() => { handleSubmitUpdateEnrollment(); }}
        onClose={() => setUpdateEnrollmentDialogOpen(false)}
      >
        <Container maxWidth="xs">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant="body2" color="error" align="center">{errorMessage}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                onChange={(event, value) => setSelectedSchool(value)}
                id="select-school-autocomplete"
                options={schools || []}
                getOptionLabel={(option) => (`${option.name}`)}
                noOptionsText="No school options: contact admin"
              // eslint-disable-next-line react/jsx-props-no-spreading
                renderInput={(params) => <TextField {...params} label="Select School" variant="outlined" />}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                onChange={(event, value) => setSelectedGrade(value)}
                id="select-grade-autocomplete"
                options={enrollmentGrades
                  ? enrollmentGrades.filter((grade) => grade.enabled === true) : []}
                getOptionLabel={(option) => (`${option.enrollmentGradeType}`)}
                noOptionsText="No grade options: select school"
                value={selectedGrade || null}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                renderInput={(params) => <TextField {...params} label="Select Grade" variant="outlined" />}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                onChange={(event, value) => setSelectedEnrollmentClass(value)}
                id="select-class-autocomplete"
                options={enrollmentClasses || []}
                getOptionLabel={(option) => (`${option.enrollmentClassName}`)}
                noOptionsText="No class options: select class"
                value={selectedEnrollmentClass || null}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                renderInput={(params) => <TextField {...params} label="Select Class" variant="outlined" />}
              />
            </Grid>
          </Grid>
        </Container>
      </KatangaDialog>
    </>
  );
}

// eslint-disable-next-line react/prop-types
export default function UpdateEnrollmentDialog({ enrollmentId }) {
  return (
    <KatangaDialogProvider>
      <Wrapped enrollmentId={enrollmentId} />
    </KatangaDialogProvider>
  );
}
