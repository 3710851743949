import {
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
} from '@mui/material';
import { CollapsibleCard, Loading, SmartTypography } from '@think-zambia-foundation/core-lib/components';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import SchoolFeesCard from './SchoolFeesCard';

function useQueryParam() {
  return new URLSearchParams(useLocation().search);
}

export default function SchoolInformationCard() {
  const { schoolId } = useParams();
  const query = useQueryParam();
  const selectedGradeId = query.get('selectedGradeId');

  const {
    errorUpdatingEnrollmentGrade,
    fetchingSchool,
    fetchSchool,
    referenceId,
    school,
    setReferenceId,
    successUpdatingEnrollmentGrade,
    updateEnrollmentGrade,
    updatingEnrollmentGrade,
  } = useKatanga();

  const history = useHistory();

  function handleSelectGrade(e, grade) {
    if (!grade.enrollmentGradeId) return;
    e.preventDefault();
    history.push(`/school/${schoolId}/?selectedGradeId=${grade.enrollmentGradeId}`);
  }

  const toggleEnableSchoolGrade = (enrollmentGradeId, enabled) => {
    setReferenceId(enrollmentGradeId);
    updateEnrollmentGrade(enrollmentGradeId, { enabled });
  };

  useEffect(() => {
    fetchSchool(schoolId);
  }, [
    errorUpdatingEnrollmentGrade,
    successUpdatingEnrollmentGrade,
  ]);

  const [grades, setGrades] = useState();
  const [_school, setSchool] = useState();
  useEffect(() => {
    if (school && school.enrollmentGrades) {
      setGrades(school.enrollmentGrades);
      setSchool(school);
    }
  }, [school]);

  if (!_school) return <></>;
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12}>
          <CollapsibleCard
            title="School Information"
          >
            <List dense>
              <ListItem>
                <ListItemText>
                  <SmartTypography
                    caption="School Name"
                    text={_school.name}
                    variant="name"
                  />
                </ListItemText>
              </ListItem>
            </List>
          </CollapsibleCard>
        </Grid>
        <Grid item xs={12}>
          <SchoolFeesCard title="School Fees" fees={_school.fees} referenceId={_school.schoolId} />
        </Grid>
        <Grid item xs={12}>
          <CollapsibleCard
            title="Select Grade"
          >
            {grades && (
              <List dense>
                {grades
                  .sort((a, b) => a.enrollmentGradeOrderId - b.enrollmentGradeOrderId)
                  .map((grade) => (
                    <React.Fragment key={grade.enrollmentGradeId}>
                      <ListItem
                        button
                        value={grade.enrollmentGradeType}
                        key={grade.enrollmentGradeId}
                        selected={selectedGradeId === `${grade.enrollmentGradeId}`}
                        onClick={(e) => {
                          handleSelectGrade(e, grade);
                        }}
                      >
                        <ListItemText>
                          <SmartTypography
                            text={grade.enrollmentGradeType}
                            caption={`Tuition: ${grade.tuition}`}
                            variant="name"
                          />
                        </ListItemText>
                        <ListItemSecondaryAction>
                          {((referenceId === grade.enrollmentGradeId)
                    && (updatingEnrollmentGrade || fetchingSchool))
                    && (<Loading />)}
                          {(!((referenceId === grade.enrollmentGradeId)
                    && (updatingEnrollmentGrade || fetchingSchool))
                    || referenceId !== grade.enrollmentGradeId) && (
                      <Switch
                        checked={grade.enabled}
                        onChange={() => toggleEnableSchoolGrade(
                          grade.enrollmentGradeId,
                          !grade.enabled,
                        )}
                      />
                          )}
                        </ListItemSecondaryAction>
                      </ListItem>
                    </React.Fragment>
                  ))}
              </List>
            )}
          </CollapsibleCard>
        </Grid>
      </Grid>
    </>
  );
}
