import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Hidden from '@mui/material/Hidden';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import { CommonWrapper } from '@think-zambia-foundation/core-lib/components';
import HistoryIcon from '@mui/icons-material/History';
import { SelectTermAndYear } from '../FormControl';

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
}));

function StudentDetailsToolbar() {
  const classes = useStyles();
  const {
    setReferenceId,
    student,
    setChangeHistoryDrawerOpen,
  } = useKatanga();

  return (
    <CommonWrapper>
      <Toolbar>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Hidden only="xs">
            <Grid item>
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link to="/" className={classes.link}>
                  <Typography variant="body2" color="textSecondary">
                    Students
                  </Typography>
                </Link>
                <Typography variant="body2" color="secondary">
                  {student.studentNumber || ''}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Hidden>
          <Grid item sm={4} xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              spacing={3}
            >
              <Grid item>
                <SelectTermAndYear />
              </Grid>
              <Grid item>
                <Tooltip title="Change History">
                  <IconButton
                    edge="end"
                    disabled={!student.changelog}
                    onClick={() => {
                      setReferenceId(student.studentId);
                      setChangeHistoryDrawerOpen(true);
                    }}
                    size="large"
                  >
                    <HistoryIcon color={!student.changelog ? '' : 'secondary'} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </CommonWrapper>
  );
}

export default StudentDetailsToolbar;
