import {
  Grid,
  Typography,
  TextField,
  Container,
  FormControl,
  MenuItem,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, {
  useEffect, useState,
} from 'react';
import { useHistory } from 'react-router';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import KatangaDialog, { KatangaDialogProvider, useKatangaDialog } from '../KatangaDialog/KatangaDialog';
import Loading from '../Loading';

const terms = ['ONE', 'TWO', 'THREE'];

function Wrapped() {
  const {
    createEnrollment,
    createEnrollmentData,
    createEnrollmentDialogOpen,
    creatingEnrollment,
    setCreateEnrollmentDialogOpen,
    student,
    fetchSchools,
    fetchingSchools,
    errorFetchingSchools,
    fetchSchoolsData,
    selectedTerm,
    selectedYear,
    setCookie,
    application,
    register,
    setRegister,
  } = useKatanga();

  const {
    setDialogOpen,
    setSubmitDisabled,
  } = useKatangaDialog();
  const history = useHistory();

  const currentYear = (new Date()).getFullYear();
  const years = Array.from(new Array(5), (val, index) => `${(currentYear - index)}`);

  useEffect(() => {
    setDialogOpen(createEnrollmentDialogOpen);
    // eslint-disable-next-line
  }, [createEnrollmentDialogOpen]);

  const [schools, setSchools] = useState();
  const [enrollmentGrades, setEnrollmentGrades] = useState();
  const [enrollmentClasses, setEnrollmentClasses] = useState();

  const [errorMessage, setErrorMessage] = useState();
  const [selectedSchool, setSelectedSchool] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('');
  const [selectedEnrollmentClass, setSelectedEnrollmentClass] = useState('');
  const [localSelectedTerm, setLocalSelectedTerm] = useState(selectedTerm);
  const [localSelectedYear, setLocalSelectedYear] = useState(selectedYear);

  useEffect(() => {
    if (selectedSchool && selectedGrade && selectedEnrollmentClass) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
    // eslint-disable-next-line
  }, [selectedSchool, selectedGrade, selectedEnrollmentClass]);

  function handleSubmitCreateEnrollment() {
    createEnrollment(
      student.studentId,
      selectedSchool.schoolId,
      selectedGrade.enrollmentGradeId,
      localSelectedTerm,
      localSelectedYear,
      selectedEnrollmentClass.enrollmentClassId,
    );
  }

  useEffect(() => {
    setLocalSelectedTerm(localSelectedTerm || 'ONE');
    setLocalSelectedYear(localSelectedYear || `${(new Date()).getFullYear()}`);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setErrorMessage();
  }, [selectedEnrollmentClass]);

  useEffect(() => {
    setSelectedEnrollmentClass();
    if (selectedGrade) {
      if (selectedGrade.enrollmentClasses) {
        setEnrollmentClasses(selectedGrade.enrollmentClasses);
        setErrorMessage();
      } else {
        setErrorMessage(`No available classes for grade ${selectedGrade.enrollmentGradeType} at ${selectedSchool.name}`);
      }
    }
    // eslint-disable-next-line
  }, [selectedGrade]);

  useEffect(() => {
    setSelectedGrade();
    if (selectedSchool) {
      if (selectedSchool.enrollmentGrades) {
        setEnrollmentGrades(selectedSchool.enrollmentGrades);
        setErrorMessage();
      } else {
        setErrorMessage(`No available grades for school ${selectedSchool.name}`);
      }
    }
  }, [selectedSchool]);

  useEffect(() => {
    if (fetchSchoolsData && fetchSchoolsData.schools) {
      setSchools(fetchSchoolsData.schools);
    }
  }, [fetchingSchools, errorFetchingSchools, fetchSchoolsData]);

  useEffect(() => {
    fetchSchools();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (createEnrollmentData) {
      setCreateEnrollmentDialogOpen(false);
    }
    // eslint-disable-next-line
  }, [createEnrollmentData]);

  function pushHistory() {
    if (register === true) {
      history.push(`/student/${application.student.studentId}`);
      setRegister(false);
    }
  }

  if (creatingEnrollment || fetchingSchools) return <Loading />;
  if (!schools) return <></>;
  return (
    <>
      <KatangaDialog
        modalTitle="Create Enrollment"
        submitAction={() => {
          handleSubmitCreateEnrollment();
          pushHistory();
        }}
        onClose={() => setCreateEnrollmentDialogOpen(false)}
      >
        <Container maxWidth="xs">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant="body2" color="error" align="center">{errorMessage}</Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormControl margin="dense" fullWidth size="medium">
                <TextField
                  select
                  label="Term"
                  id="term"
                  value={localSelectedTerm}
                  variant="outlined"
                >
                  { terms.map((term) => (
                    <MenuItem
                      onClick={() => {
                        setLocalSelectedTerm(term);
                        setCookie('katangaSelectedTerm', term, { path: '/' });
                      }}
                      value={term}
                      key={term}
                    >
                      {term}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormControl margin="dense" fullWidth size="medium">
                <TextField
                  select
                  id="year-outlined"
                  value={localSelectedYear}
                  label="Year"
                  variant="outlined"
                >
                  { years.map((year) => (
                    <MenuItem
                      ey={year}
                      onClick={() => {
                        setLocalSelectedYear(year);
                        setCookie('katangaSelectedYear', year, { path: '/' });
                      }}
                      value={year}
                      key={year}
                    >
                      {year}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                onChange={(event, value) => setSelectedSchool(value)}
                id="select-school-autocomplete"
                options={schools || []}
                getOptionLabel={(option) => (`${option.name}`)}
                noOptionsText="No school options: contact admin"
              // eslint-disable-next-line react/jsx-props-no-spreading
                renderInput={(params) => <TextField {...params} label="Select School" variant="outlined" />}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                onChange={(event, value) => setSelectedGrade(value)}
                id="select-grade-autocomplete"
                options={enrollmentGrades
                  ? enrollmentGrades.filter((grade) => grade.enabled === true) : []}
                getOptionLabel={(option) => (`${option.enrollmentGradeType}`)}
                noOptionsText="No grade options: select school"
                value={selectedGrade || null}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                renderInput={(params) => <TextField {...params} label="Select Grade" variant="outlined" />}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                onChange={(event, value) => setSelectedEnrollmentClass(value)}
                id="select-class-autocomplete"
                options={enrollmentClasses || []}
                getOptionLabel={(option) => (`${option.enrollmentClassName}`)}
                noOptionsText="No class options: select class"
                value={selectedEnrollmentClass || null}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                renderInput={(params) => <TextField {...params} label="Select Class" variant="outlined" />}
              />
            </Grid>
          </Grid>
        </Container>
      </KatangaDialog>
    </>
  );
}

export default function CreateEnrollmentDialog() {
  return (
    <KatangaDialogProvider>
      <Wrapped />
    </KatangaDialogProvider>
  );
}
