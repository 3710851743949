/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { TableListProvider, useAuth, useTableList } from '@think-zambia-foundation/core-lib/context';
import { TableListBase, MobileTableListBase } from '@think-zambia-foundation/core-lib/components';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Hidden } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import Loading from '../../components/Loading';
import StudentListFilterDialog from '../../components/StudentListFilterDialog/StudentListFilterDialog';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function QueryParameterProvider() {
  const { page, setPage } = useTableList();
  const [query] = useState(useQuery());
  const history = useHistory();

  useEffect(() => {
    const pageQuery = query.get('page');
    if (pageQuery) {
      setPage(parseInt(pageQuery, 10));
    }
  }, []);

  useEffect(() => {
    history.push({
      search: `?page=${page}`,
    });
  }, [page]);

  return (<></>);
}

function StudentList() {
  const {
    errorFetchingStudents,
    fetchingStudents,
    fetchStudents,
    filteredStudents,
  } = useKatanga();

  useState(() => {
    fetchStudents();
  }, []);

  const {
    setStudentListFilterDialogOpen,
  } = useKatanga();

  function fabActionCallBack() {
    setStudentListFilterDialogOpen(true);
  }

  const [desktopTableMetadata] = useState({
    href: '/student',
    tableKey: 'studentId',
    fabAction: {
      callback: fabActionCallBack,
      icon: <FilterListIcon />,
    },
    cells: [
      {
        key: 'studentNumber',
        header: 'Student Number',
        variant: 'id',
      }, {
        key: 'studentName',
        header: 'Name',
        value(data) { return `${data.firstName} ${data.lastName}`; },
        variant: 'name',
      }, {
        key: 'guardianName',
        header: 'Guardian',
        value(data) { return `${data.guardian.firstName} ${data.guardian.lastName}`; },
        variant: 'name',
      }, {
        key: 'phone',
        header: 'Phone',
        value(data) { return `${data.guardian.phone1}`; },
        variant: 'phone',
      }, {
        key: 'email',
        header: 'Email',
        value(data) { return `${data.guardian.email}`; },
        variant: 'email',
      }],
  });

  const [mobileTableMetadata] = useState({
    href: '/student',
    tableKey: 'studentId',
    searchIcon: <FilterListIcon />,
    onClickSearchIcon: () => { setStudentListFilterDialogOpen(true); },
    avatarSrc(data) { return `${data.imageUrl}}`; },
    avatarAlt(data) { return `${data.firstName} ${data.lastName}`; },
    primary(data) { return `${data.firstName} ${data.lastName}`; },
    secondary(data) { return `${data.studentNumber}`; },
  });

  const { hasPermissionToClaim } = useAuth();
  if (fetchingStudents) return <Loading />;
  if (errorFetchingStudents) return <>Error Fetching Students. Please Try Again</>;
  if (!hasPermissionToClaim('katangaStudentUser')) return <>You do not have permissions to this page. contact the system admin</>;
  if (!filteredStudents) return (<></>);
  return (
    <>
      <Hidden only="xs">
        <TableListProvider tableMetadata={desktopTableMetadata}>
          <QueryParameterProvider />
          <TableListBase
            data={filteredStudents}
            tableMetadata={desktopTableMetadata}
          />
        </TableListProvider>
      </Hidden>
      <Hidden smUp>
        <TableListProvider tableMetadata={mobileTableMetadata}>
          <QueryParameterProvider />
          <MobileTableListBase
            data={filteredStudents}
            tableMetadata={mobileTableMetadata}
          />
        </TableListProvider>
      </Hidden>
      <StudentListFilterDialog />
    </>
  );
}

export default StudentList;
