import React, { useEffect, useState } from 'react';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import {
  TableList, CollapsibleCard,
} from '@think-zambia-foundation/core-lib/components';
import {
  useAuth, TableListProvider,
} from '@think-zambia-foundation/core-lib/context';
import UpdateEnrollmentSubjectDialog from '../updateStudentEnrollmentSubject';

function Wrapped() {
  const {
    refetchStudentById,
    student,
    updateEnrollmentSubjectDialogOpen,
  } = useKatanga();

  useEffect(() => {
    if (!updateEnrollmentSubjectDialogOpen && refetchStudentById) {
      refetchStudentById();
    }
  }, [updateEnrollmentSubjectDialogOpen]);

  if (!student || !student.enrollment || !student.enrollment.enrollmentSubjects) return <></>;
  return (
    <>
      <CollapsibleCard
        title="Subjects"
      >
        <TableList
          data={student.enrollment.enrollmentSubjects}
          disableSearch
          elevation={0}
        />
      </CollapsibleCard>
      <UpdateEnrollmentSubjectDialog />
    </>
  );
}

export default function StudentEnrollmentSubjectReport() {
  const { hasPermissionToClaim } = useAuth();

  const {
    setUpdateEnrollmentSubjectDialogOpen,
    setUpdateEnrollmentSubjectId,
  } = useKatanga();

  function calculateLetterGrade(score) {
    if (!score) return '-';
    if (score <= 59) return 'F';
    if (score <= 69) return 'D';
    if (score <= 79) return 'C';
    if (score <= 89) return 'B';
    return 'A';
  }

  function onClickCell(enrollmentSubjectId) {
    if (hasPermissionToClaim('katangaScoreAdmin')) {
      setUpdateEnrollmentSubjectDialogOpen(true);
      setUpdateEnrollmentSubjectId(enrollmentSubjectId);
    }
  }

  const [tableMetadata] = useState({
    tableKey: 'enrollmentSubjectId',
    onClickCell,
    cells: [
      {
        key: 'name',
        header: 'Subject',
        value(data) { return data.subject.name; },
        variant: 'name',
      }, {
        key: 'studentId',
        header: 'Teacher',
        value(data) { return `${data.subject.staff.firstName} ${data.subject.staff.lastName}`; },
        variant: 'name',
      }, {
        key: 'schoolId',
        header: 'Score (%)',
        value(data) { return `${data.score || '-'}`; },
        variant: 'name',
      }, {
        key: 'gradeId',
        header: 'Grade',
        value(data) { return `${calculateLetterGrade(data.score)}`; },
        variant: 'name',
      }],
  });

  return (
    <TableListProvider tableMetadata={tableMetadata}>
      <Wrapped />
    </TableListProvider>
  );
}
