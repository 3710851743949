/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField, Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import { useCore } from '@think-zambia-foundation/core-lib/context';
import { useLocation, useParams } from 'react-router';
import Loading from '../Loading';

function useQueryParam() {
  return new URLSearchParams(useLocation().search);
}

export default function AddEnrollmentClassDialog() {
  const { schoolId } = useParams();
  const query = useQueryParam();
  const selectedGradeId = query.get('selectedGradeId');
  const [errorMessage, setErrorMessage] = useState();
  const [selectedStaffId, setSelectedStaffId] = useState();
  const [enrollmentClassName, setEnrollmentClassName] = useState();
  const [capacity, setCapacity] = useState();
  const {
    successAddingEnrollmentClass,
    addEnrollmentClass,
    addEnrollmentClassDialogOpen,
    addingEnrollmentClass,
    setAddEnrollmentClassDialogOpen,
  } = useKatanga();

  const {
    errorFetchingStaffList,
    fetchingStaffList,
    fetchStaff,
    fetchStaffListData,
  } = useCore();

  const handleSubmitAddEnrollmentClass = () => {
    addEnrollmentClass({
      schoolId,
      gradeId: selectedGradeId,
      staffId: selectedStaffId,
      enrollmentClassName,
      capacity: parseInt(capacity, 10),
    });
  };

  useEffect(() => {
    fetchStaff();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (errorFetchingStaffList) {
      setErrorMessage('error fetching staff list');
    }
  }, [errorFetchingStaffList]);

  useEffect(() => {
    if (successAddingEnrollmentClass) {
      setAddEnrollmentClassDialogOpen(false);
    }
    // eslint-disable-next-line
  }, [successAddingEnrollmentClass]);

  if (addingEnrollmentClass || fetchingStaffList) return <Loading />;
  return (
    <Dialog
      maxWidth="md"
      open={addEnrollmentClassDialogOpen}
      onClose={() => setAddEnrollmentClassDialogOpen(false)}
      aria-labelledby="add-enrollment-class-dialog-title"
    >
      <DialogTitle id="add-enrollment-class-dialog-title">
        <Typography align="center">
          Add Enrollment Class
        </Typography>
      </DialogTitle>
      <DialogContent>
        {errorMessage && (
          <Typography variant="body2" color="error">
            {errorMessage}
          </Typography>
        )}
        <FormControl>
          <Container component="main" maxWidth="xs">
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="add-enrollment-class-name"
              label="Enrollment Class Name"
              name="Enrollment Class Name"
              autoFocus
              onChange={(e) => setEnrollmentClassName(e.target.value)}
            />
            <TextField
              variant="outlined"
              required
              number
              fullWidth
              id="add-enrollment-class-capacity"
              label="Capacity"
              name="Capacity"
              autoFocus
              margin="normal"
              onChange={(e) => setCapacity(e.target.value)}
            />
            <Autocomplete
              onChange={(event, value) => setSelectedStaffId(value.staffId)}
              id="select-staff-autocomplete"
              options={fetchStaffListData
                && fetchStaffListData.staffList ? fetchStaffListData.staffList : []}
              getOptionLabel={(option) => (`${option.firstName} ${option.lastName}`)}
              noOptionsText="No staff options: contact admin"
              fullWidth
              renderInput={(params) => <TextField {...params} margin="normal" label="Select Teacher" variant="outlined" />}
            />
          </Container>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            onClick={() => setAddEnrollmentClassDialogOpen(false)}
            color="primary"
            size="large"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmitAddEnrollmentClass}
            color="primary"
            size="large"
          >
            Submit
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
