import {
  SelectSchoolStep,
  StudentInformationStep,
  GuardianInformationStep,
  ContactInformationStep,
} from '@think-zambia-foundation/katanga-lib/components';
import { useKatangaStudentApplication } from '@think-zambia-foundation/katanga-lib/context';
import { ApplicationStepper } from '@think-zambia-foundation/core-lib/components';
import { useApplication } from '@think-zambia-foundation/core-lib/context';
import React, { useEffect } from 'react';

function Wrapped() {
  const {
    addressLine1,
    addressLine2,
    applicationValidationSuccess,
    city,
    contactInfoValidationSuccess,
    country,
    email,
    guardianFirstName,
    guardianId,
    guardianInfoValidationSuccess,
    guardianLastName,
    phone1,
    phone2,
    postalCode,
    previousSchool,
    religion,
    selectedGender,
    selectedGradeId,
    selectedSchoolId,
    selectSchoolValidationSuccess,
    studentDateOfBirth,
    studentFirstName,
    studentInfoValidationSuccess,
    studentLastName,
    studentMiddleName,
    setAddressLine1,
    setAddressLine2,
    setCity,
    setCountry,
    setEmail,
    setGuardianFirstName,
    setGuardianId,
    setGuardianLastName,
    setPhone1,
    setPhone2,
    setPostalCode,
    setPreviousSchool,
    setReligion,
    setSelectedGender,
    setSelectedGradeId,
    setSelectedSchoolId,
    setStudentDateOfBirth,
    setStudentFirstName,
    setStudentLastName,
    setStudentMiddleName,
    submitApplication,
  } = useKatangaStudentApplication();

  const {
    activeStep,
    steps,
    handleBack,
    handleNext,
    setSteps,
  } = useApplication();

  useEffect(() => {
    setSteps([
      {
        title: 'Select School and Grade',
        step: <SelectSchoolStep
          selectedGradeId={selectedGradeId}
          selectedSchoolId={selectedSchoolId}
          setSelectedGradeId={setSelectedGradeId}
          setSelectedSchoolId={setSelectedSchoolId}
        />,
        validation: selectSchoolValidationSuccess,
      },
      {
        title: 'Enter Student Information',
        step: <StudentInformationStep
          studentFirstName={studentFirstName}
          studentMiddleName={studentMiddleName}
          studentLastName={studentLastName}
          studentDateOfBirth={studentDateOfBirth}
          selectedGender={selectedGender}
          previousSchool={previousSchool}
          religion={religion}
          setStudentFirstName={setStudentFirstName}
          setStudentLastName={setStudentLastName}
          setStudentMiddleName={setStudentMiddleName}
          setStudentDateOfBirth={setStudentDateOfBirth}
          setSelectedGender={setSelectedGender}
          setPreviousSchool={setPreviousSchool}
          setReligion={setReligion}
        />,
        validation: studentInfoValidationSuccess,
      },
      {
        title: 'Enter Guardian Information',
        step: <GuardianInformationStep
          guardianId={guardianId}
          guardianFirstName={guardianFirstName}
          guardianLastName={guardianLastName}
          setGuardianId={setGuardianId}
          setGuardianFirstName={setGuardianFirstName}
          setGuardianLastName={setGuardianLastName}
        />,
        validation: guardianInfoValidationSuccess,
      },
      {
        title: 'Enter Contact Information',
        step: <ContactInformationStep
          phone1={phone1}
          phone2={phone2}
          email={email}
          addressLine1={addressLine1}
          addressLine2={addressLine2}
          city={city}
          postalCode={postalCode}
          country={country}
          setPhone1={setPhone1}
          setPhone2={setPhone2}
          setEmail={setEmail}
          setAddressLine1={setAddressLine1}
          setAddressLine2={setAddressLine2}
          setCity={setCity}
          setPostalCode={setPostalCode}
          setCountry={setCountry}
        />,
        validation: contactInfoValidationSuccess,
      },
    ]);
    // eslint-disable-next-line
  }, [selectSchoolValidationSuccess,
    applicationValidationSuccess,
    contactInfoValidationSuccess,
    guardianFirstName,
    guardianId,
    guardianInfoValidationSuccess,
    guardianLastName,
    previousSchool,
    religion,
    selectedGender,
    studentDateOfBirth,
    studentFirstName,
    studentInfoValidationSuccess,
    studentLastName,
    studentMiddleName,
    phone1,
    phone2,
    email,
    addressLine1,
    addressLine2,
    city,
    postalCode,
    country,
  ]);

  function handleSubmitApplication() {
    submitApplication();
  }

  if (!steps) return <>No Steps</>;
  return (
    <ApplicationStepper
      handleSubmitApplication={() => handleSubmitApplication()}
      activeStep={activeStep}
      handleBack={handleBack}
      handleNext={handleNext}
      steps={steps}
    />
  );
}

function ApplicationForm() {
  return (
    <Wrapped />
  );
}
export default ApplicationForm;
