/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField, Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import { useCore } from '@think-zambia-foundation/core-lib/context';
import Loading from '../Loading';

export default function UpdateEnrollmentClassDialog({ enrollmentClassId }) {
  const [errorMessage, setErrorMessage] = useState();
  const [selectedStaffId, setSelectedStaffId] = useState();
  const [enrollmentClassName, setEnrollmentClassName] = useState();
  const [capacity, setCapacity] = useState();
  const {
    successUpdatingEnrollmentClass,
    updateEnrollmentClass,
    updateEnrollmentClassDialogOpen,
    updatingEnrollmentClass,
    setUpdateEnrollmentClassDialogOpen,
  } = useKatanga();

  const {
    errorFetchingStaffList,
    fetchingStaffList,
    fetchStaff,
    fetchStaffListData,
  } = useCore();

  const handleSubmitUpdateEnrollmentClass = () => {
    updateEnrollmentClass({
      enrollmentClassId,
      staffId: selectedStaffId,
      enrollmentClassName,
      capacity: parseInt(capacity, 10),
    });
  };

  useEffect(() => {
    fetchStaff();
  }, []);

  useEffect(() => {
    if (errorFetchingStaffList) {
      setErrorMessage('error fetching staff list');
    }
  }, [errorFetchingStaffList]);

  useEffect(() => {
    if (successUpdatingEnrollmentClass) {
      setUpdateEnrollmentClassDialogOpen(false);
    }
  }, [successUpdatingEnrollmentClass]);

  if (updatingEnrollmentClass || fetchingStaffList) return <Loading />;
  return (
    <Dialog
      maxWidth="md"
      open={updateEnrollmentClassDialogOpen}
      onClose={() => setUpdateEnrollmentClassDialogOpen(false)}
      aria-labelledby="update-enrollment-class-dialog-title"
    >
      <DialogTitle id="update-enrollment-class-dialog-title">
        <Typography align="center">
          Update Enrollment Class
        </Typography>
      </DialogTitle>
      <DialogContent>
        {errorMessage && (
          <Typography variant="body2" color="error">
            {errorMessage}
          </Typography>
        )}
        <FormControl>
          <Container component="main" maxWidth="xs">
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="add-enrollment-class-name"
              label="Enrollment Class Name"
              name="Enrollment Class Name"
              autoFocus
              onChange={(e) => setEnrollmentClassName(e.target.value)}
            />
            <TextField
              variant="outlined"
              required
              number
              fullWidth
              id="add-enrollment-class-capacity"
              label="Capacity"
              name="Capacity"
              autoFocus
              margin="normal"
              onChange={(e) => setCapacity(e.target.value)}
            />
            <Autocomplete
              onChange={(event, value) => setSelectedStaffId(value.staffId)}
              id="select-staff-autocomplete"
              options={fetchStaffListData
                && fetchStaffListData.staffList ? fetchStaffListData.staffList : []}
              getOptionLabel={(option) => (`${option.firstName} ${option.lastName}`)}
              noOptionsText="No staff options: contact admin"
              fullWidth
              renderInput={(params) => <TextField {...params} margin="normal" label="Select Teacher" variant="outlined" />}
            />
          </Container>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            onClick={() => setUpdateEnrollmentClassDialogOpen(false)}
            color="primary"
            size="large"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmitUpdateEnrollmentClass}
            color="primary"
            size="large"
          >
            Submit
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
