import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import {
  Button,
  CardMedia, Tooltip,
} from '@mui/material';
import { SmartTypography, CollapsibleCard } from '@think-zambia-foundation/core-lib/components';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import { GENERATE_STUDENT_LOGIN } from '@think-zambia-foundation/katanga-lib/graphql';
import { useAuth } from '@think-zambia-foundation/core-lib/context';
import { useMutation } from '@apollo/client';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import GetInitials from '../Common';

function StudentInfo() {
  const {
    client,
    student,
    fetchStudentById,
    updateStudent,
  } = useKatanga();
  const getAge = (birthDate) => Math.floor(
    (new Date() - new Date(birthDate).getTime()) / 3.154e+10,
  );
  const { hasPermissionToClaim } = useAuth();
  const [generateStudentLoginMutation,
    { data: generateStudentLoginData }] = useMutation(GENERATE_STUDENT_LOGIN, {
    client,
  });

  const generateStudentLogin = (studentId) => {
    generateStudentLoginMutation({
      variables: {
        studentId,
      },
    });
  };

  useEffect(() => {
    if (generateStudentLoginData && generateStudentLoginData.generateStudentLogin) {
      fetchStudentById(generateStudentLoginData.generateStudentLogin.studentId);
    }
  }, [generateStudentLoginData]);

  const [balanceOwed, setBalanceOwed] = useState(0);
  useEffect(() => {
    if (student && student.enrollment) {
      const totalAmountDue = student.enrollment.fees
        .reduce((total, fee) => total + fee.amount, 0);
      const totalAmountPaid = student.enrollment.payments
        .reduce((total, payment) => total + payment.paymentAmount, 0);
      setBalanceOwed(totalAmountDue - totalAmountPaid);
    } else {
      setBalanceOwed(0);
    }
  }, [student]);

  if (!student) return <>Loading</>;
  return (
    <CollapsibleCard
      title="Student Information"
      cardActionCallback={() => updateStudent({
        studentId: student.studentId,
        studentStatus: 'ARCHIVE',
      })}
      icon={<ArchiveOutlinedIcon />}
      disableCard={(student.studentStatus === 'ARCHIVE') || !(hasPermissionToClaim('katangaStudentsAdmin'))}
    >
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={4} sm={4}>
            <CardMedia>
              <Avatar>
                {GetInitials(student.firstName, student.lastName)}
              </Avatar>
            </CardMedia>
          </Grid>
          <Grid item xs={8} sm={8}>
            <SmartTypography
              caption="Name"
              text={`${student.firstName} ${student.lastName}`}
              variant="name"
            />
          </Grid>
          <Grid item xs={9} sm={4}>
            <SmartTypography caption="Gender" text={`${student.gender}`} />
          </Grid>
          <Grid item xs={9} sm={4}>
            <SmartTypography caption="Age" text={`${getAge(student.dateOfBirth)}`} />
          </Grid>
        </Grid>
        {student.studentStatus !== 'PRE_REGISTERED' && (
          <>
            <SmartTypography caption="Status" text={`${student.studentStatus}`} />
            <SmartTypography caption="Student Number" text={`${student.studentNumber}`} />
          </>
        )}
        <SmartTypography caption="Balance" text={`${balanceOwed}`} />
      </CardContent>
      {!student.uid && hasPermissionToClaim('katangaAdmin')
      && (
      <CardContent>
        <Grid item xs={12}>
          <Tooltip
            title={(student.guardian && student.guardian.email)
              ? '' : 'Add guardian email to enable this button'}
            aria-label="add"
          >
            <Button
              onClick={() => generateStudentLogin(student.studentId)}
              color="secondary"
              variant="contained"
              disabled={!(student.guardian && student.guardian.email)}
              style={{ borderRadius: 0 }}
            >
              GENERATE LOGIN
            </Button>
          </Tooltip>
        </Grid>
      </CardContent>
      )}
    </CollapsibleCard>
  );
}

export default StudentInfo;
