/* eslint-disable no-unused-vars */
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Hidden from '@mui/material/Hidden';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

function StudentInformationRegistrationForm({
  newStudent,
  setNewStudentFirstName,
  setNewStudentMiddleName,
  setNewStudentLastName,
  setNewStudentGender,
  setNewStudentDateOfBirth,
}) {
  const classes = useStyles();
  return (
    <div>
      <Card className={classes.root} variant="outlined" square>
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={12}>
              <Typography color="textSecondary" gutterBottom>
                Student Information
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                fullWidth
                id="first-name"
                label="First Name"
                value={newStudent.firstName}
                onChange={setNewStudentFirstName}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                fullWidth
                id="middle-name"
                label="Middle Name"
                value={newStudent.middleName}
                onChange={setNewStudentMiddleName}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                fullWidth
                id="last-name"
                label="Last Name"
                value={newStudent.lastName}
                onChange={setNewStudentLastName}
              />
            </Grid>
            <Hidden only="xs">
              <Grid item sm={3} />
            </Hidden>
            <Grid item xs={12} sm={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputVariant="outlined"
                  disableToolbar
                  inputFormat="DD-MMM-YYYY"
                  fullWidth
                  id="date-of-birth"
                  label="Date of birth"
                  value={newStudent.dateOfBirth}
                  onChange={setNewStudentDateOfBirth}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  renderInput={(props) => <TextField label="Date" helperText="Something" />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Select
                label="gender"
                id="gender"
                defaultValue="Male"
                value={newStudent.gender}
                onChange={setNewStudentGender}
                variant="outlined"
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

StudentInformationRegistrationForm.propTypes = {
  newStudent: PropTypes.func.isRequired,
  setNewStudentFirstName: PropTypes.func.isRequired,
  setNewStudentMiddleName: PropTypes.func.isRequired,
  setNewStudentLastName: PropTypes.func.isRequired,
  setNewStudentGender: PropTypes.func.isRequired,
  setNewStudentDateOfBirth: PropTypes.func.isRequired,
};

export default StudentInformationRegistrationForm;
