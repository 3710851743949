import React from 'react';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { lighten } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import PropTypes from 'prop-types';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
      theme.palette.mode === 'light'
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

function EnhancedReportToolbar({
  numSelected,
  updateTerm,
  updateYear,
}) {
  const classes = useToolbarStyles();
  const currentYear = (new Date()).getFullYear();
  const years = Array.from(new Array(5), (val, index) => `${(index + currentYear)}`);

  return (
    <Paper elevation={0} variant="outlined" square>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item>
            <FormControl variant="outlined" margin="dense" className={classes.formControl}>
              {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                  {numSelected}
                  {' '}
                  selected
                </Typography>
              ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                  Students
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <FormControl variant="outlined" margin="dense" className={classes.formControl}>
                  <InputLabel id="term-label">Term</InputLabel>
                  <Select
                    labelId="term-label"
                    label="Term"
                    id="term"
                    defaultValue={1}
                    onChange={updateTerm}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant="outlined" margin="dense" className={classes.formControl}>
                  <InputLabel id="year-label">Year</InputLabel>
                  <Select
                    labelId="year-label"
                    id="year-outlined"
                    defaultValue={years[0]}
                    onChange={updateYear}
                    label="Year"
                    fullWidth
                  >
                    { years.map((year) => <MenuItem key={year} value={year}>{year}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </Paper>
  );
}

EnhancedReportToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  updateTerm: PropTypes.func.isRequired,
  updateYear: PropTypes.func.isRequired,
};

export default EnhancedReportToolbar;
