import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { MobileTableList, TableList } from '@think-zambia-foundation/core-lib/components';
import { useAuth } from '@think-zambia-foundation/core-lib/context';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import { Hidden } from '@mui/material';
import Loading from '../../components/Loading';
import StudentRegistrationDialog from '../../components/StudentRegistrationDialog/StudentRegistrationDialog';

const PENDING_APPLICATION_STATUS = 'PENDING';

export default function ApplicationList() {
  const [applications, setApplications] = useState();
  const {
    setStudentRegistrationDialogOpen,
    fetchApplications,
    fetchApplicationsData,
    fetchingApplications,
    errorFetchingApplications,
  } = useKatanga();

  useEffect(() => {
    fetchApplications();
  }, []);

  useEffect(() => {
    if (!setStudentRegistrationDialogOpen) {
      fetchApplications();
    }
  }, [setStudentRegistrationDialogOpen]);

  useEffect(() => {
    if (fetchApplicationsData && fetchApplicationsData.applications) {
      setApplications(fetchApplicationsData.applications
        .filter((application) => application.applicationStatus === PENDING_APPLICATION_STATUS));
    } else {
      setApplications([]);
    }
  }, [fetchApplicationsData, errorFetchingApplications]);

  function fabActionCallBack() {
    setStudentRegistrationDialogOpen(true);
  }

  const { hasPermissionToClaim } = useAuth();
  if (fetchingApplications) return <Loading />;
  if (errorFetchingApplications) return <>Error Fetching Applications. Please Try Again</>;
  if (!hasPermissionToClaim('katangaStudentsAdmin')) return <>You do not have permissions to this page. contact the system admin</>;
  return (
    <>
      <Hidden only="xs">
        <TableList
          data={applications}
          tableMetadata={{
            href: '/application',
            tableKey: 'applicationId',
            fabAction: {
              callback: fabActionCallBack,
              icon: <AddIcon />,
            },
            cells: [
              {
                key: 'applicationId',
                header: 'Application ID',
                variant: 'id',
              }, {
                key: 'studentId',
                header: 'Applicant Name',
                value(data) { return `${data.student.firstName} ${data.student.lastName}`; },
                variant: 'name',
              }, {
                key: 'schoolId',
                header: 'Applying To',
                value(data) { return `${data.school.name}`; },
                variant: 'name',
              }, {
                key: 'gradeId',
                header: 'Grade',
                value(data) { return `${data.grade.enrollmentGradeType}`; },
                variant: 'name',
              }, {
                key: 'applicationStatus',
                header: 'Status',
                variant: 'name',
              }],
          }}
        />
      </Hidden>
      <Hidden smUp>
        <MobileTableList
          data={applications}
          tableMetadata={{
            href: '/application',
            tableKey: 'applicationId',
            avatarSrc(data) { return `${data.student.imageUrl}}`; },
            avatarAlt(data) { return `${data.student.firstName} ${data.student.lastName}`; },
            primary(data) { return `${data.student.firstName} ${data.student.lastName}`; },
            secondary(data) { return `${data.applicationStatus}`; },
          }}
        />
      </Hidden>
      <StudentRegistrationDialog />
    </>
  );
}
