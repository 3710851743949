import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import BodyBackgroundColor from 'react-body-backgroundcolor';
import { StyledEngineProvider } from '@mui/material/styles';
import { CookiesProvider } from 'react-cookie';
import { KatangaProvider } from '@think-zambia-foundation/katanga-lib/context';
import { CoreApp } from '@think-zambia-foundation/core-lib/components';
import { AuthProvider, CoreProvider } from '@think-zambia-foundation/core-lib/context';
import { ThemeWrapper } from '@think-zambia-foundation/core-lib/theme';
import theme from './theme';
import menu from './menu';
import routes from './routes';
import { ChangeHistoryDrawer } from './components';

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_KATANGA_EXPRESS_URL}`,
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ThemeWrapper theme={theme}>
    <StyledEngineProvider injectFirst>
      <BodyBackgroundColor backgroundColor="whitesmoke">
        <ApolloProvider client={client}>
          <CookiesProvider>
            <AuthProvider>
              <KatangaProvider>
                <CoreProvider>
                  <CoreApp app={{
                    name: 'Katanga',
                    loginPath: '/login',
                    menu,
                    routes,
                  }}
                  />
                  {
                  // TODO: move to core app
                    <ChangeHistoryDrawer />
                  }
                </CoreProvider>
              </KatangaProvider>
            </AuthProvider>
          </CookiesProvider>
        </ApolloProvider>
      </BodyBackgroundColor>
    </StyledEngineProvider>
  </ThemeWrapper>,
  document.getElementById('root'),
);
