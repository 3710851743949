export default function GetInitials(firstName, lastName) {
  let initial = '';
  if (firstName && firstName.length > 0) {
    initial = initial.concat(firstName.charAt(0));
  }
  if (lastName && lastName.length > 0) {
    initial = initial.concat(lastName.charAt(0));
  }
  return initial;
}
