import { StaffList } from '@think-zambia-foundation/core-lib/components';
import {
  ApplicationDetails,
  KatangaStaffDetails,
  MyStudents,
  PaymentReportV2,
  StudentDetails,
  StudentList,
  StudentRegistration,
} from './pages';
import ApplicationList from './pages/ApplicationList';
import CapacityReport from './pages/CapacityReport';
import EnrollmentReport from './pages/EnrollmentReport';
import SchoolDetails from './pages/SchoolDetails';
import SchoolList from './pages/SchoolList/SchoolList';

const routes = [
  {
    exact: true,
    path: '/',
    component: StudentList,
    title: 'Students',
  },
  {
    exact: true,
    path: '/mystudents',
    component: MyStudents,
    title: 'My Students',
  },
  {
    exact: true,
    path: '/student/registration',
    component: StudentRegistration,
    title: 'Student Registration',
  },
  {
    path: '/student/:studentId',
    component: StudentDetails,
    title: 'Student Details',
  },
  {
    exact: true,
    path: '/report/payment',
    component: PaymentReportV2,
    title: 'Payment Report',
    enabled: process.env.REACT_APP_PAYMENT_REPORT,
  },
  {
    exact: true,
    path: '/report/enrollment',
    component: EnrollmentReport,
    title: 'Enrollment Report',
    enabled: process.env.REACT_APP_ENROLLMENT_REPORT,
  }, {
    exact: true,
    path: '/report/capacity',
    component: CapacityReport,
    title: 'Capacity Report',
    enabled: process.env.REACT_APP_ENROLLMENT_REPORT,
  },
  {
    exact: true,
    path: '/staff',
    component: StaffList,
    title: 'Staff',
  },
  {
    exact: true,
    path: '/staff/:staffId',
    component: KatangaStaffDetails,
    title: 'Staff Details',
  },
  {
    exact: true,
    path: '/school',
    component: SchoolList,
    title: 'Schools',
  },
  {
    exact: true,
    path: '/school/:schoolId',
    component: SchoolDetails,
    title: 'Schools Details',
  },
  {
    exact: true,
    path: '/application',
    component: ApplicationList,
    title: 'Applications',
  },
  {
    exact: true,
    path: '/application/:applicationId',
    component: ApplicationDetails,
    title: 'Application Details',
  },
];

export default routes;
