import React, {
  useState, createContext, useContext,
} from 'react';

const CapacityReportContext = createContext();

export function useCapacityReport() {
  return useContext(CapacityReportContext);
}

// eslint-disable-next-line react/prop-types
export function CapacityReportProvider({ children }) {
  const [capacityReportData, setCapacityReportData] = useState();
  const [filteredCapacityReportData, setFilteredCapacityReportData] = useState();
  const [capacityReportFilterDialogOpen, setCapacityReportFilterDialogOpen] = useState(false);
  const [enrollmentClassFilter, setEnrollmentClassFilter] = useState();
  const [gradeFilter, setGradeFilter] = useState();

  return (
    <CapacityReportContext.Provider
      value={{
        capacityReportData,
        capacityReportFilterDialogOpen,
        enrollmentClassFilter,
        filteredCapacityReportData,
        gradeFilter,
        setCapacityReportData,
        setCapacityReportFilterDialogOpen,
        setEnrollmentClassFilter,
        setFilteredCapacityReportData,
        setGradeFilter,
      }}
    >
      {children}
    </CapacityReportContext.Provider>
  );
}
