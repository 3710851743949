/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { FormControl, Grid, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useMutation } from '@apollo/client';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import { ADD_FEE } from '@think-zambia-foundation/katanga-lib/graphql';
import KatangaDialog, {
  KatangaDialogProvider,
  useKatangaDialog,
} from '../KatangaDialog/KatangaDialog';
import Loading from '../Loading';

const feeTypes = [
  'Library Charge',
  'Other',
  'PTA',
  'Pupil Identity Card',
];

// eslint-disable-next-line react/prop-types
function Wrapped() {
  const [selectedFeeType, setSelectedFeeType] = useState();
  const [feeDescription, setFeeDescription] = useState();
  const [feeAmount, setFeeAmount] = useState();

  const {
    setDialogOpen,
    setSubmitDisabled,
  } = useKatangaDialog();

  const {
    addFeesDialogOpen,
    referenceId,
    setAddFeesDialogOpen,
    setReferenceId,
    client,
  } = useKatanga();

  const [addFee, {
    loading,
    error,
    data,
  }] = useMutation(ADD_FEE, {
    client,
  });

  useEffect(() => {
    if (referenceId
      && selectedFeeType
      && feeDescription
      && feeAmount) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
    // eslint-disable-next-line
  }, [selectedFeeType, feeDescription, feeAmount]);

  useEffect(() => {
    setDialogOpen(addFeesDialogOpen);
    // eslint-disable-next-line
  }, [addFeesDialogOpen]);

  useEffect(() => {
    if (data) {
      setReferenceId();
      setAddFeesDialogOpen(false);
    }
    // eslint-disable-next-line
  }, [data]);

  function handleSubmitAddFees() {
    addFee({
      variables: {
        amount: parseFloat(feeAmount),
        description: feeDescription,
        feeType: selectedFeeType,
        referenceId,
      },
    });
  }

  if (loading) return <Loading />;
  if (error) return <>404</>;
  return (
    <KatangaDialog
      modalTitle="Add Fees"
      submitAction={() => handleSubmitAddFees()}
      onClose={() => {
        setReferenceId();
        setAddFeesDialogOpen(false);
      }}
    >
      <FormControl>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => setSelectedFeeType(value)}
              id="select-fee-autocomplete"
              options={feeTypes}
              disableClearable
              value={selectedFeeType || null}
              fullWidth
              renderInput={(params) => <TextField {...params} label="Select Fee Type" variant="outlined" />}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="normal"
              label="Fee Description"
              name="Fee Description"
              id="Fee-description-tf"
              disabled={!selectedFeeType}
              onChange={(event) => setFeeDescription(event.target.value)}
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 30);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              type="number"
              id="fee-amount-tf"
              onChange={(event) => setFeeAmount(event.target.value)}
              variant="outlined"
              margin="normal"
              label="Fee Amount"
              name="Fee Amount"
              disabled={!selectedFeeType}
            />
          </Grid>
        </Grid>
      </FormControl>
    </KatangaDialog>
  );
}

// eslint-disable-next-line react/prop-types
export default function AddFeesDialog() {
  return (
    <KatangaDialogProvider>
      <Wrapped />
    </KatangaDialogProvider>
  );
}
