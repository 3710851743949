import {
  Grid,
  ListItem,
  ListItemText,
} from '@mui/material';
import { CollapsibleCard, SmartTypography } from '@think-zambia-foundation/core-lib/components';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import SchoolFeesCard from './SchoolFeesCard';
import SubjectsCard from './SubjectsCard';
import { UpdateEnrollmentClassDialog } from '../../components';

function useQueryParam() {
  return new URLSearchParams(useLocation().search);
}

export default function ClassInformationCard() {
  const query = useQueryParam();
  const selectedGradeId = query.get('selectedGradeId');
  const selectedClassId = query.get('selectedClassId');
  const [_school, setSchool] = useState();
  const [_class, setClass] = useState();

  const {
    school,
    setUpdateEnrollmentClassDialogOpen,
  } = useKatanga();

  useEffect(() => {
    if (school) {
      setSchool(school);
      const selectedGrade = school.enrollmentGrades
        .find((grade) => grade.enrollmentGradeId === selectedGradeId);
      if (selectedGrade && selectedGrade.enrollmentClasses) {
        setClass(selectedGrade.enrollmentClasses
          .find((c) => c.enrollmentClassId === selectedClassId));
      } else {
        setClass();
      }
    }
  }, [school, selectedGradeId, selectedClassId]);

  if (!_school) return <></>;
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      {_class && (
        <Grid item xs={12}>
          <CollapsibleCard
            title="Class Information"
            cardActionCallback={() => setUpdateEnrollmentClassDialogOpen(true)}
            icon={<EditTwoToneIcon />}
          >
            {_class && (
            <>
              <ListItem>
                <ListItemText>
                  <SmartTypography
                    caption="Class Name"
                    text={_class.enrollmentClassName}
                    variant="name"
                  />
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <SmartTypography
                    caption="Class Teacher"
                    text={`${_class.staff.firstName} ${_class.staff.lastName}`}
                    variant="name"
                  />
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <SmartTypography
                    caption="Capacity"
                    text={`${_class.capacity}`}
                    variant="name"
                  />
                </ListItemText>
              </ListItem>
              <UpdateEnrollmentClassDialog enrollmentClassId={_class.enrollmentClassId} />
            </>
            )}
          </CollapsibleCard>
        </Grid>
      )}
      {_class && (
        <>
          <Grid item xs={12}>
            <SchoolFeesCard title="Class Fees" fees={_class.fees} referenceId={_class.enrollmentClassId} />
          </Grid>
        </>
      )}
      {_class && (
      <Grid item xs={12}>
        <SubjectsCard subjects={_class.subjects} referenceId={_class.enrollmentClassId} title="Class Subjects" />
      </Grid>
      )}
    </Grid>
  );
}
