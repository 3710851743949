/* eslint-disable react/jsx-props-no-spreading */
import { Grid, TextField, Autocomplete } from '@mui/material';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import KatangaDialog, { KatangaDialogProvider, useKatangaDialog } from '../../components/KatangaDialog/KatangaDialog';
import { usePaymentReport } from './PaymentReportV2Context';

function Wrapped() {
  const {
    paymentReportData,
    paymentReportFilterDialogOpen,
    setPaymentReportV2FilterDialogOpen,
  } = usePaymentReport();

  const {
    setCookie,
    setSelectedTerm,
    setSelectedYear,
  } = useKatanga();

  const [enrollmentClasses, setEnrollmentClasses] = useState();
  const [selectedEnrollmentClassFilter, setSelectedEnrollmentClassFilter] = useState();
  const [term, setTerm] = useState();
  const [year, setYear] = useState();
  const terms = ['ONE', 'TWO', 'THREE'];
  const currentYear = (new Date()).getFullYear();
  const years = Array.from(new Array(5), (val, index) => `${(currentYear - index)}`);

  const {
    setDialogOpen,
    setSubmitDisabled,
  } = useKatangaDialog();

  useEffect(() => {
    if (selectedEnrollmentClassFilter || term || year) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [selectedEnrollmentClassFilter, term, year]);

  useEffect(() => {
    if (paymentReportData) {
      const uniqueEnrollmentClasses = [];
      paymentReportData.forEach((row) => {
        if (!uniqueEnrollmentClasses.some((c) => c.enrollmentClassId === row.enrollmentClassId)) {
          uniqueEnrollmentClasses.push(
            {
              enrollmentClassId: row.enrollmentClassId,
              enrollmentClassName: row.enrollmentClassName,
            },
          );
        }
      });
      setEnrollmentClasses(uniqueEnrollmentClasses);
    }
  }, [paymentReportData]);

  useEffect(() => {
    setDialogOpen(paymentReportFilterDialogOpen);
  }, [paymentReportFilterDialogOpen]);

  const history = useHistory();

  return (
    <KatangaDialog
      modalTitle="Filter Payment Report"
      submitAction={() => {
        if (term) {
          setSelectedTerm(term);
          setCookie('katangaSelectedTerm', term, { path: '/' });
        }
        if (year) {
          setSelectedYear(year);
          setCookie('katangaSelectedYear', year, { path: '/' });
        }
        if (selectedEnrollmentClassFilter) {
          history.push({
            search: `?enrollmentClassId=${selectedEnrollmentClassFilter.enrollmentClassId}`,
          });
        }
        setSelectedEnrollmentClassFilter();
        setPaymentReportV2FilterDialogOpen(false);
      }}
      onClose={() => setPaymentReportV2FilterDialogOpen(false)}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Autocomplete
            onChange={(event, value) => {
              setSelectedEnrollmentClassFilter(value);
            }}
            id="select-enrollment-class-autocomplete"
            options={enrollmentClasses ?? []}
            getOptionLabel={(option) => (`${option.enrollmentClassName}`)}
            noOptionsText="No enrollment class options: contact admin"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Enrollment Class"
                variant="outlined"
                margin="normal"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            onChange={(e, value) => {
              setTerm(value);
            }}
            id="select-term-autocomplete"
            options={terms ?? []}
            value={term}
            disableClearable
            noOptionsText="No term options: contact admin"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Term"
                variant="outlined"
                margin="normal"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            onChange={(e, value) => {
              setYear(value);
            }}
            id="select-year-autocomplete"
            options={years ?? []}
            value={year}
            disableClearable
            noOptionsText="No year options: contact admin"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Year"
                variant="outlined"
                margin="normal"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </KatangaDialog>
  );
}

export default function PaymentReportV2FilterDialog() {
  return (
    <KatangaDialogProvider>
      <Wrapped />
    </KatangaDialogProvider>
  );
}
