import React, { useState, useEffect } from 'react';
import {
  Card, CardContent, Grid, Hidden, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Chart,
  PieSeries,
  Title,
} from '@devexpress/dx-react-chart-bootstrap4';
import '@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css';
import { Animation } from '@devexpress/dx-react-chart';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';

function feesReducer(accumulator, currentValue) {
  return accumulator + (currentValue.amount ? currentValue.amount : 0);
}
function enrollmentReducer(accumulator, currentValue) {
  return accumulator + (currentValue.fees ? currentValue.fees.reduce(feesReducer, 0) : 0);
}
function enrollmentClassReducer(accumulator, currentValue) {
  return accumulator + (currentValue.enrollments
    ? currentValue.enrollments.reduce(enrollmentReducer, 0) : 0);
}
function enrollmentGradeReducer(accumulator, currentValue) {
  return accumulator + (currentValue.enrollmentClasses
    ? currentValue.enrollmentClasses.reduce(enrollmentClassReducer, 0) : 0);
}
function schoolReducer(accumulator, currentValue) {
  return accumulator + (currentValue.enrollmentGrades
    ? currentValue.enrollmentGrades.reduce(enrollmentGradeReducer, 0) : 0);
}

function paymentsReducer(accumulator, currentValue) {
  return accumulator + (currentValue.paymentAmount
    ? currentValue.paymentAmount : 0);
}
function enrollmentPaymentsReducer(accumulator, currentValue) {
  return accumulator + (currentValue.payments
    ? currentValue.payments.reduce(paymentsReducer, 0) : 0);
}
function enrollmentClassPaymentsReducer(accumulator, currentValue) {
  return accumulator + (currentValue.enrollments
    ? currentValue.enrollments.reduce(enrollmentPaymentsReducer, 0) : 0);
}
function enrollmentGradePaymentsReducer(accumulator, currentValue) {
  return accumulator + (currentValue.enrollmentClasses
    ? currentValue.enrollmentClasses.reduce(enrollmentClassPaymentsReducer, 0) : 0);
}
function schoolPaymentsReducer(accumulator, currentValue) {
  return accumulator + (currentValue.enrollmentGrades
    ? currentValue.enrollmentGrades.reduce(enrollmentGradePaymentsReducer, 0) : 0);
}

const useStyles = makeStyles((theme) => ({
  total: {
    padding: theme.spacing(2, 0, 0, 0),
  },
}));

export default function TuitionTotals() {
  const classes = useStyles();
  const {
    schoolsWithEnrollments,
  } = useKatanga();
  const [schools, setSchools] = useState();
  const [totalFees, setTotalFees] = useState();
  const [totalPayments, setTotalPayments] = useState();

  useEffect(() => {
    if (schoolsWithEnrollments && schoolsWithEnrollments.schools) {
      setSchools(schoolsWithEnrollments.schools);
    }
  }, [schoolsWithEnrollments]);

  useEffect(() => {
    if (schools) {
      setTotalFees(schools.reduce(schoolReducer, 0));
      setTotalPayments(schools.reduce(schoolPaymentsReducer, 0));
    }
  }, [schools]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item sm={6} xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Card square>
              <CardContent>
                <Typography variant="body2" color="secondary" gutterBottom>
                  Total Due
                </Typography>
                <Typography variant="h5" component="h2" className={classes.total}>
                  {totalFees ? totalFees.toFixed(2) : ''}
                </Typography>
                <Typography variant="caption" color="textSecondary" gutterBottom>
                  ZMW
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card square>
              <CardContent>
                <Typography variant="body2" color="secondary" gutterBottom>
                  Total Paid
                </Typography>
                <Typography variant="h5" component="h2" className={classes.total}>
                  {totalPayments ? totalPayments.toFixed(2) : ''}
                </Typography>
                <Typography variant="caption" color="textSecondary" gutterBottom>
                  ZMW
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card square>
              <CardContent>
                <Typography variant="body2" color="secondary" gutterBottom>
                  Total Outstanding
                </Typography>
                <Typography variant="h5" component="h2" className={classes.total}>
                  {totalFees && totalPayments ? (totalFees - totalPayments).toFixed(2) : ''}
                </Typography>
                <Typography variant="caption" color="textSecondary" gutterBottom>
                  ZMW
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card square>
              <CardContent>
                <Typography variant="body2" color="secondary" gutterBottom>
                  % Paid
                </Typography>
                <Typography variant="h5" component="h2" className={classes.total}>
                  {totalFees && totalPayments ? ((totalPayments * 100) / totalFees).toFixed(0) : ''}
                </Typography>
                <Typography variant="caption" color="textSecondary" gutterBottom>
                  Percent
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Hidden only="xs">
        <Grid item sm={6} xs={12}>
          <Chart
            data={
            [
              { total: 'Due', amount: totalFees },
              { total: 'Paid', amount: totalPayments },
            ]
          }
          >
            <PieSeries
              valueField="amount"
              argumentField="total"
              innerRadius={0.9}
            />
            <Title
              text="Payment Totals"
            />
            <Animation />
          </Chart>
        </Grid>
      </Hidden>
    </Grid>
  );
}
