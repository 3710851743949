import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { TableList } from '@think-zambia-foundation/core-lib/components';
import { useAuth } from '@think-zambia-foundation/core-lib/context';
import { GET_STUDENTS_BY_UID } from '@think-zambia-foundation/katanga-lib/graphql';

import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import Loading from '../../components/Loading';

export default function MyStudents() {
  const [students, setStudents] = useState();
  const {
    hasPermissionToClaim,
    currentUser,
  } = useAuth();

  const {
    client,
  } = useKatanga();

  // TODO: Only fetch if hasPermissionToClaim("katangaStudent")
  const [getStudentsByUid, {
    loading: fetchingStudentsByUID,
    error: errorFetchingStudentsByUID,
    data: fetchStudentsByUIDData,
  }] = useLazyQuery(GET_STUDENTS_BY_UID, {
    client,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (currentUser && hasPermissionToClaim('katangaStudent')) {
      getStudentsByUid({
        variables: {
          uid: currentUser.uid,
        },
      });
    }
  }, [hasPermissionToClaim, currentUser]);

  useEffect(() => {
    if (fetchStudentsByUIDData && fetchStudentsByUIDData.studentByUID) {
      setStudents(fetchStudentsByUIDData.studentByUID);
    } else {
      setStudents([]);
    }
    // eslint-disable-next-line
  }, [fetchStudentsByUIDData, errorFetchingStudentsByUID]);

  if (fetchingStudentsByUID) return <Loading />;
  if (errorFetchingStudentsByUID) return <>Error Fetching Students. Please Try Again</>;
  if (!hasPermissionToClaim('katangaStudent')) return <>You do not have permissions to this page. contact the system admin</>;
  return (
    <>
      <TableList
        data={students}
        tableMetadata={{
          href: '/student',
          tableKey: 'studentId',
          cells: [
            {
              key: 'studentNumber',
              header: 'Student Number',
              variant: 'id',
            }, {
              key: 'studentName',
              header: 'Name',
              value(data) { return `${data.firstName} ${data.lastName}`; },
              variant: 'name',
            }, {
              key: 'guardianName',
              header: 'Guardian',
              value(data) { return `${data.guardian.firstName} ${data.guardian.lastName}`; },
              variant: 'name',
            }, {
              key: 'phone',
              header: 'Phone',
              value(data) { return `${data.guardian.phone1}`; },
              variant: 'phone',
            }, {
              key: 'email',
              header: 'Email',
              value(data) { return `${data.guardian.email}`; },
              variant: 'email',
            }],
        }}
      />
    </>
  );
}
