import React from 'react';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import { SmartTypography, CollapsibleCard } from '@think-zambia-foundation/core-lib/components';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import { useAuth } from '@think-zambia-foundation/core-lib/context';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import CreateEnrollmentDialog from '../CreateEnrollmentDialog';

import UpdateEnrollmentDialog from '../UpdateEnrollmentDialog';

function StudentEnrollmentInfo() {
  const { student, setCreateEnrollmentDialogOpen, setUpdateEnrollmentDialogOpen } = useKatanga();
  const { hasPermissionToClaim } = useAuth();

  if (student && student.studentStatus !== 'ACTIVE') return <></>;
  return (
    <CollapsibleCard
      title="Enrollment Information"
      cardActionCallback={() => (!(student && student.enrollment)
        ? setCreateEnrollmentDialogOpen(true)
        : setUpdateEnrollmentDialogOpen(true))}
      icon={!(student && student.enrollment) ? <AddTwoToneIcon /> : <EditTwoToneIcon />}
      disableCard={!(hasPermissionToClaim('katangaAdmin'))}
    >
      <CardContent>
        {(student && student.enrollment)
              && (
              <Grid container>
                <Grid item xs={12}>
                  <SmartTypography
                    caption="School"
                    text={`${student.enrollment.enrollmentClass.enrollmentGrade.school.name}`}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <SmartTypography
                    caption="Grade"
                    text={`${student.enrollment.enrollmentClass.enrollmentGrade.enrollmentGradeType}`}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <SmartTypography
                    caption="Class"
                    text={`${student.enrollment.enrollmentClass.enrollmentClassName}`}
                  />
                </Grid>
                { student.enrollment.enrollmentClass.staff && (
                <Grid item xs={6} sm={4}>
                  <SmartTypography
                    caption="Grade Teacher"
                    text={`${student.enrollment.enrollmentClass.staff.firstName} ${student.enrollment.enrollmentClass.staff.lastName}`}
                    variant="name"
                  />
                </Grid>
                )}
              </Grid>
              )}
      </CardContent>
      <CreateEnrollmentDialog />
      { student && student.enrollment
      && <UpdateEnrollmentDialog enrollmentId={student.enrollment.enrollmentId} /> }
    </CollapsibleCard>
  );
}

export default StudentEnrollmentInfo;
