import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { MobileTableList, TableList } from '@think-zambia-foundation/core-lib/components';
import { useAuth } from '@think-zambia-foundation/core-lib/context';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import { Hidden } from '@mui/material';
import Loading from '../../components/Loading';
import CreateSchoolDialog from '../../components/CreateSchoolDialog/CreateSchoolDialog';

function SchoolList() {
  const [schools, setSchools] = useState();
  const {
    errorFetchingSchools,
    fetchingSchools,
    fetchSchoolsData,
    setCreateSchoolDialogOpen,
    fetchSchools,
    createSchoolData,
  } = useKatanga();

  useEffect(() => {
    if (fetchSchoolsData && fetchSchoolsData.schools) {
      setSchools(fetchSchoolsData.schools);
    } else {
      setSchools([]);
    }
    // eslint-disable-next-line
  }, [fetchSchoolsData, errorFetchingSchools]);

  useEffect(() => {
    fetchSchools();
    // eslint-disable-next-line
  }, [createSchoolData]);

  function fabActionCallBack() {
    setCreateSchoolDialogOpen(true);
  }

  const { hasPermissionToClaim } = useAuth();

  if (fetchingSchools) return <Loading />;
  if (errorFetchingSchools) return <>Error Fetching Schools. Please Try Again</>;
  if (!hasPermissionToClaim('katangaSchoolAdmin')) return <>You do not have permissions to this page. contact the system admin</>;
  return (
    <>
      <Hidden only="xs">
        <TableList
          data={schools}
          tableMetadata={{
            href: '/school',
            tableKey: 'schoolId',
            fabAction: {
              callback: fabActionCallBack,
              icon: <AddIcon />,
            },
            cells: [{
              key: 'schoolId',
              header: 'School ID',
              variant: 'id',
            }, {
              key: 'name',
              header: 'Name',
              variant: 'name',
            }],
          }}
        />
      </Hidden>
      <Hidden smUp>
        <MobileTableList
          data={schools}
          tableMetadata={{
            href: '/school',
            tableKey: 'schoolId',
            avatarSrc(data) { return `${data.imageUrl}}`; },
            avatarAlt(data) { return `${data.name} ${data.name}`; },
            primary(data) { return `${data.name}`; },
            secondary(data) { return `${data.schoolId}`; },
          }}
        />
      </Hidden>
      <CreateSchoolDialog />
    </>
  );
}

export default SchoolList;
