/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {
  Avatar,
  CardContent,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Toolbar,
  Typography,
  IconButton,
} from '@mui/material';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import CloseIcon from '@mui/icons-material/Close';

function ChangeHistoryItem({ createdDateTime, name, change }) {
  function stringAvatar(text) {
    return {
      children: <Typography variant="body2">{`${text.split(' ')[0][0]}${text.split(' ')[1][0]}`}</Typography>,
    };
  }
  return (
    <>
      <ListItem alignItems="center">
        <ListItemAvatar>
          <Avatar {...stringAvatar(name)} />
        </ListItemAvatar>
        <ListItemText
          primary={(
            <Typography variant="body2">
              {createdDateTime}
            </Typography>
          )}
          secondary={(
            <>
              <Typography
                sx={{ display: 'inline', paddingRight: '.4em' }}
                component="span"
                variant="body2"
                color="secondary"
              >
                {name}
              </Typography>
              {change}
            </>
)}
        />
      </ListItem>
    </>
  );
}

export default function SwipeableTemporaryDrawer() {
  const [changeHistory, setChangeHistory] = useState();
  const {
    changeHistoryDrawerOpen,
    fetchChangeHistoryByReferenceIdData,
    referenceId,
    fetchChangeHistory,
    setChangeHistoryDrawerOpen,
  } = useKatanga();

  useEffect(() => {
    if (referenceId) {
      fetchChangeHistory();
    }
  }, [referenceId, changeHistoryDrawerOpen]);

  useEffect(() => {
    if (fetchChangeHistoryByReferenceIdData && fetchChangeHistoryByReferenceIdData.changelog) {
      setChangeHistory(fetchChangeHistoryByReferenceIdData.changelog);
    } else {
      setChangeHistory([]);
    }
  }, [fetchChangeHistoryByReferenceIdData]);

  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        elevation={1}
        hideBackdrop
        open={changeHistoryDrawerOpen}
        onClose={() => setChangeHistoryDrawerOpen(false)}
        onOpen={() => setChangeHistoryDrawerOpen(true)}
      >
        <Toolbar />
        <CardContent style={{ maxWidth: '100vw', width: '425px' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <IconButton
              onClick={() => setChangeHistoryDrawerOpen(false)}
              style={{ marginLeft: 'auto', paddingRight: '.6em' }}
            >
              <CloseIcon color="secondary" />
            </IconButton>
          </div>
          {changeHistory && changeHistory.map((changeHistoryItem) => (
            <ChangeHistoryItem
              key={changeHistoryItem.changeLogId}
              createdDateTime={changeHistoryItem.createdDateTime}
              name={changeHistoryItem.userName}
              change={changeHistoryItem.change}
            />
          ))}
        </CardContent>
      </SwipeableDrawer>
    </div>
  );
}
