import React, { useEffect } from 'react';
import { KatangaStudentApplicationProvider, useKatanga, useKatangaStudentApplication } from '@think-zambia-foundation/katanga-lib/context';
import { ApplicationProvider } from '@think-zambia-foundation/core-lib/context';
import ApplicationForm from './ApplicationForm';
import KatangaDialog, { KatangaDialogProvider, useKatangaDialog } from '../KatangaDialog/KatangaDialog';

function Wrapped() {
  const {
    createApplicationData,
    creatingApplication,
  } = useKatangaStudentApplication();

  const {
    registerStudentData,
    setStudentRegistrationDialogOpen,
    studentRegistrationDialogOpen,
  } = useKatanga();

  const {
    setDialogOpen,
    setSubmitDisabled,
  } = useKatangaDialog();

  useEffect(() => {
    if (creatingApplication) {
      setSubmitDisabled(true);
    }
  }, [creatingApplication]);

  useEffect(() => {
    setDialogOpen(studentRegistrationDialogOpen);
    // eslint-disable-next-line
    }, [studentRegistrationDialogOpen,
  ]);

  const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

  useEffect(() => {
    if (registerStudentData) {
      setStudentRegistrationDialogOpen(false);
    }
  }, [registerStudentData]);

  useEffect(() => {
    if (createApplicationData) {
      setStudentRegistrationDialogOpen(false);
    }
  }, [createApplicationData]);

  return (
    <>
      <KatangaDialog
        modalTitle="Register Student"
        onClose={() => setStudentRegistrationDialogOpen(false)}
      >
        <ApplicationForm
          createApplicationSuccessCallback={() => {
            sleep(250).then(() => setStudentRegistrationDialogOpen(false));
          }}
        />
      </KatangaDialog>
    </>
  );
}

export default function StudentRegistrationDialog() {
  return (
    <KatangaDialogProvider>
      <ApplicationProvider>
        <KatangaStudentApplicationProvider>
          <Wrapped />
        </KatangaStudentApplicationProvider>
      </ApplicationProvider>
    </KatangaDialogProvider>
  );
}
