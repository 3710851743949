import React, { createContext, useState } from 'react';

const EnhancedTableToolbarContext = createContext();

// eslint-disable-next-line react/prop-types
function EnhancedTableToolbarProvider({ children }) {
  const [selected, setSelected] = React.useState([]);
  const [numSelected, setNumSelected] = useState();
  const [completed, setCompleted] = useState();
  const [data, setData] = useState();
  const [headCells, setHeadCells] = useState([]);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <EnhancedTableToolbarContext.Provider value={{
      selected,
      numSelected,
      completed,
      data,
      headCells,
      setHeadCells,
      isSelected,
      setSelected,
      setData,
      setCompleted,
      setNumSelected,
    }}
    >
      {children}
    </EnhancedTableToolbarContext.Provider>
  );
}

const EnhancedTableToolbarConsumer = EnhancedTableToolbarContext.Consumer;

export { EnhancedTableToolbarProvider, EnhancedTableToolbarContext, EnhancedTableToolbarConsumer };
