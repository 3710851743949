/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Container,
  FormControl, TextField, Typography,
} from '@mui/material';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import Loading from '../Loading';
import KatangaDialog, { KatangaDialogProvider, useKatangaDialog } from '../KatangaDialog/KatangaDialog';

function Wrapped() {
  const [errorMessage, setErrorMessage] = useState();
  const [schoolName, setSchoolName] = useState();
  const {
    setCreateSchoolDialogOpen,
    createSchoolDialogOpen,
    createSchool,
    createSchoolData,
    creatingSchool,
    errorCreatingSchool,
  } = useKatanga();

  const {
    setDialogOpen,
    setSubmitDisabled,
  } = useKatangaDialog();

  useEffect(() => {
    if (schoolName) {
      setSubmitDisabled(false);
    }
    // eslint-disable-next-line
  }, [schoolName]);

  useEffect(() => {
    setDialogOpen(createSchoolDialogOpen);
    // eslint-disable-next-line
  }, [createSchoolDialogOpen]);

  const handleSubmitSchool = () => {
    createSchool(
      schoolName,
    );
  };

  useEffect(() => {
    if (errorCreatingSchool) {
      setErrorMessage('error creating school');
    }
  }, [errorCreatingSchool]);

  useEffect(() => {
    if (createSchoolData) {
      setCreateSchoolDialogOpen(false);
    }
    // eslint-disable-next-line
  }, [createSchoolData]);

  if (creatingSchool) return <Loading />;
  return (
    <KatangaDialog
      modalTitle="Create School"
      submitAction={() => handleSubmitSchool()}
      onClose={() => setCreateSchoolDialogOpen(false)}
    >
      {errorMessage && (
        <Typography variant="body2" color="error">
          {errorMessage}
        </Typography>
      )}
      <FormControl>
        <Container component="main" maxWidth="xs">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="school-name"
            label="School Name"
            name="School Name"
            autoFocus
            onChange={(e) => setSchoolName(e.target.value)}
          />
        </Container>
      </FormControl>
    </KatangaDialog>
  );
}

export default function CreateSchoolDialog() {
  return (
    <KatangaDialogProvider>
      <Wrapped />
    </KatangaDialogProvider>
  );
}
