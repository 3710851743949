import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router';
import { useKatanga } from '@think-zambia-foundation/katanga-lib/context';
import { useAuth } from '@think-zambia-foundation/core-lib/context';
import ApplicationInfo from '../../components/ApplicationInfo';
import StudentContactInfo from '../../components/StudentContactInfo';
import Loading from '../../components/Loading';

function StudentDetails() {
  const { applicationId } = useParams();

  const {
    application,
    setApplication,
    setStudent,
    student,
    archiveApplicationData,
    rejectApplicationData,
    fetchApplication,
    fetchApplicationByIdData,
    fetchingApplicationById,
    errorFetchingApplicationById,
  } = useKatanga();

  useEffect(() => {
    if (applicationId) {
      fetchApplication({ applicationId });
    }
  }, [applicationId]);

  useEffect(() => {
    if (archiveApplicationData && archiveApplicationData.archiveApplication) {
      fetchApplication({ applicationId });
    }
  }, [archiveApplicationData]);

  useEffect(() => {
    if (rejectApplicationData && rejectApplicationData.rejectStudent) {
      fetchApplication({ applicationId });
    }
  }, [rejectApplicationData]);

  useEffect(() => {
    if (fetchApplicationByIdData && fetchApplicationByIdData.application) {
      setApplication(fetchApplicationByIdData.application);
      setStudent(fetchApplicationByIdData.application.student);
    }
    // eslint-disable-next-line
  }, [fetchApplicationByIdData, errorFetchingApplicationById, fetchingApplicationById]);

  const { hasPermissionToClaim } = useAuth();

  if (fetchingApplicationById) return <Loading />;
  if (!application) return <></>;
  if (!hasPermissionToClaim('katangaStudentsAdmin')) return <>You do not have permissions to this page. contact the system admin</>;
  if (application) {
    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <ApplicationInfo />
          </Grid>
          {student && (
            <Grid item xs={12} sm={8}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <StudentContactInfo />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </>
    );
  }
}

export default StudentDetails;
