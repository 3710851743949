import React, {
  createContext, useContext, useState,
} from 'react';

const TotalPaymentReportFilterContext = createContext();

export function useTotalPaymentReportFilter() {
  return useContext(TotalPaymentReportFilterContext);
}

// eslint-disable-next-line react/prop-types
export function TotalPaymentReportFilterProvider({ children }) {
  const [schoolFilter, setSchoolFilter] = useState();
  const [gradeFilter, setGradeFilter] = useState();
  const [enrollmentClassFilter, setEnrollmentClassFilter] = useState();
  const [balanceFilter, setBalanceFilter] = useState();

  return (
    <TotalPaymentReportFilterContext.Provider
      value={{
        schoolFilter,
        gradeFilter,
        enrollmentClassFilter,
        balanceFilter,
        setBalanceFilter,
        setSchoolFilter,
        setGradeFilter,
        setEnrollmentClassFilter,
      }}
    >
      {children}
    </TotalPaymentReportFilterContext.Provider>
  );
}
