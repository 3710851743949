import React, {
  createContext, useContext, useState,
} from 'react';

const EnrollmentReportFilterContext = createContext();

export function useEnrollmentReportFilter() {
  return useContext(EnrollmentReportFilterContext);
}

// eslint-disable-next-line react/prop-types
export function EnrollmentReportFilterProvider({ children }) {
  const [schoolFilter, setSchoolFilter] = useState();
  const [gradeFilter, setGradeFilter] = useState();
  const [enrollmentClassFilter, setEnrollmentClassFilter] = useState();

  return (
    <EnrollmentReportFilterContext.Provider
      value={{
        schoolFilter,
        gradeFilter,
        enrollmentClassFilter,
        setSchoolFilter,
        setGradeFilter,
        setEnrollmentClassFilter,
      }}
    >
      {children}
    </EnrollmentReportFilterContext.Provider>
  );
}
